import { Component, OnInit, NgZone, ApplicationRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let Chart: any;

@Component({
  selector: 'app-edit-usuario',
  templateUrl: './edit-usuario.component.html'
})
export class EditUsuarioComponent implements OnInit {
  public cliente:boolean = (window.location.pathname.indexOf('clientes') != -1);
  public colaborador:boolean = (window.location.pathname.indexOf('colaboradores') != -1);
  public guias:boolean = (window.location.pathname.indexOf('guias') != -1);
  public title:string = 'Nuevo usuario';
  public data:any = {
    id: '',
    id_rol: 1,
    imagen: '',
    img: '',
    referencia: '',
    alias: '',
    nombre: '',
    apellidos: '',
    codpostal: '',
    direccion: '',
    localidad: '',
    telefono: '',
    email: '',
    clave: '',
    acceso: '1',
  };
  public reservas:any = {
    total_reservas_semana: 0
  };
  public imgsubir:string = '';
  public roles = [];
  public dataitem:any = {};
  public codigos_postales = [];
  public fechas_bloqueadas = [];
  public myChart:any;
  public reservasTarjetas:any = [];
  public anios:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    
    let anio = new Date().getFullYear();
    this.anios.push(anio);
    for(var i = 0; i <= 5; i++){
      anio--;
      this.anios.push(anio);
    }

    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo usuario';
      if (this.cliente) this.title = 'Nuevo cliente';
      if (this.colaborador) this.title = 'Nuevo colaborador';
      if (this.guias) this.title = 'Nuevo guía';
    } else {
      this.title = this.data.nombre + (this.data.apellidos ? ' ' + this.data.apellidos : '');
    }
    if (this.cliente) this.data.id_rol = 3;
    if (this.colaborador) this.data.id_rol = 4;
    if (this.guias) this.data.id_rol = 5;
    if(this.data.id_rol == 4){
      const d = new Date();
      let month = d.getMonth();
      let year = d.getFullYear();
      month++;
      let strMonth;
      if(month < 10){
        strMonth = '0'+month;
      }
      setTimeout(() => {
        if(month < 10){
          $('#select-mes').val('0'+String(month));
        }else{
          $('#select-mes').val(String(month));
        }
        $('#select-anio').val(this.anios[0]);
      }, 500);
      this.globals.Api('/datos-generales-colaborador', {id_colaborador: this.data.id, mes_select: strMonth, anio_select: year}).subscribe(data => {
        if (!data) return;
        this.reservas = data.data;
        this.reservasTarjetas = data.reservas_tarjetas;
        this.CargarGrafica();
      });
    }
    this.globals.Api('/roles').subscribe(data => {
      if (!data || data.length == 0) return;
      this.roles = data;
      if (!this.cliente) this.roles.splice(this.roles.findIndex(el => el.id == 3), 1);
    });
    // this.globals.Api('/codigos-postales').subscribe(data => {
    //   if (!data || data.length == 0) return;
    //   this.codigos_postales = data;
    // });
    
    this.fechas_bloqueadas = [];
    if (this.data.fechas_bloqueadas) {
      for (let item of this.data.fechas_bloqueadas) {
        if (!item || !item.fecha_format2) continue;
        this.fechas_bloqueadas.push(item.fecha_format2);
      }
    }
    $('#calendario-cierres').datepicker({
      todayBtn: "linked",
      keyboardNavigation: false,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      weekStart: 1,
      language: 'es',
      format: "dd/mm/yyyy",
      beforeShowDay: date => {
        let fecha = moment(date).format('YYYY-MM-DD');
        if (this.fechas_bloqueadas.indexOf(fecha) != -1) {
          return {classes: 'bloqueada'};
        }
      }
    });
    $('#calendario-cierres').datepicker().on('changeDate', ev => {
      let fecha = moment($('#calendario-cierres').datepicker('getDate')).format('YYYY-MM-DD');
      if (this.fechas_bloqueadas.indexOf(fecha) != -1) {
        swal({
          title: "¿Desbloquear fecha?",
          type: "warning",
          showCancelButton: true,
          confirmButtonClass: "btn-primary",
          confirmButtonText: "Sí",
          cancelButtonText: "No",
          closeOnConfirm: true
        },() => {
          if (!this.data.id || this.data.id == '') {
            if (this.data.fechas_bloquedas) this.data.fechas_bloquedas.splice(this.data.fechas_bloquedas.findIndex(el => el.fecha == fecha), 1);
            if (this.fechas_bloqueadas) this.fechas_bloqueadas.splice(this.fechas_bloqueadas.findIndex(el => el == fecha), 1);
            return;
          }
          this.globals.Api('/usuarios-fechas-bloqueadas-del', {id_usuario: this.data.id, fecha: fecha}).subscribe(data => {
            if (!data || data.error) {
              swal('Error', 'Se ha producido un error', 'error');return;
            }
            if (this.data.fechas_bloquedas) this.data.fechas_bloquedas.splice(this.data.fechas_bloquedas.findIndex(el => el.fecha == fecha), 1);
            if (this.fechas_bloqueadas) this.fechas_bloqueadas.splice(this.fechas_bloqueadas.findIndex(el => el == fecha), 1);
          });
        });
        return;
      };
      swal({
        title: "¿Bloquear fecha?",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-primary",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
        closeOnConfirm: true
      },() => {
        if (!this.data.id || this.data.id == '') {
          if (!this.data.fechas_bloquedas) this.data.fechas_bloquedas = [];
          this.data.fechas_bloquedas.push({id_usuario: this.data.id, fecha: fecha});
          this.fechas_bloqueadas.push(fecha);
          return;
        }
        this.globals.Api('/usuarios-fechas-bloqueadas-add', {id_usuario: this.data.id, fecha: fecha}).subscribe(data => {
          if (!data || data.error) {
            swal('Error', 'Se ha producido un error', 'error');return;
          }
          if (!this.data.fechas_bloquedas) this.data.fechas_bloquedas = [];
          if (!this.fechas_bloqueadas) this.fechas_bloqueadas = [];
          this.data.fechas_bloquedas.push({id_usuario: this.data.id, fecha: fecha});
          this.fechas_bloqueadas.push(fecha);
        });
      });
    });
  }
  
  SelecImagen() {
    let input = this.globals.FileInput('image/*', event => {
      this.globals.Base64Image(input, base64 => {
        this.data.imagen = base64;
        this.imgsubir = base64;
        this.appref.tick();
      });
    }, 800);
    input.click();
  }
  BorrarImagen(event:any) {
    event.stopPropagation();
    swal({
      title: "¿Borrar imagen del usuario?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      if (!this.data.id || this.data.id == '') {
        this.imgsubir = '';
        this.data.imagen = '';
        return;
      }
      this.globals.Api('/borrar-imagen-usuario', {id: this.data.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.imgsubir = '';
        this.data.imagen = '';
        this.appref.tick();
      });
    });
  }
  
  DatosLocalidad() {
    this.globals.Api('/localidad', {codpostal: this.data.codpostal}).subscribe(data => {
      if (!data) {
        this.data.localidad = '';
        this.appref.tick();
        return;
      }
      this.data.localidad = data.localidad
      this.appref.tick();
    });
  }

  EditarClave() {
    this.data.title = 'Editar contraseña usuario';
    this.data.clave = '';
    $('#modal-editar-clave-usuario').modal('show');
  }
  GenerarClave() {
    this.data.clave = this.globals.GeneratePassword();
    this.appref.tick();
  }
  GuardarClaveUsuario() {
    if (
      this.data.clave == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    $.buttonloading('btnGuardarClaveUsuarioModal', 'Enviando');
    this.globals.Api('/editar-clave-usuario', this.data).subscribe(data => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      $('#modal-editar-clave-usuario').modal('hide');
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('btnGuardarClaveUsuarioModal');
    });
  }

  Cancelar() {
    if (this.cliente) {
      this.ngZone.run(() => this.router.navigateByUrl('/clientes')).then();
      return;
    }
    if (this.colaborador) {
      this.ngZone.run(() => this.router.navigateByUrl('/colaboradores')).then();
      return;
    }
    this.ngZone.run(() => this.router.navigateByUrl('/usuarios')).then();
  }
  Guardar() {
    if (
      this.data.nombre == ''
      || this.data.apellidos == ''
      || (this.data.id_rol == '' && this.data.id_rol != 0)
      || this.data.email == ''
      || (this.data.clave == '' && this.data.id == '')
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }
    if (!this.globals.ValidateEmail(this.data.email)) {
      swal('Email no válido', 'Por favor, introduzca un email válido.', 'error');return;
    }

    this.data.img = null;
    if (this.imgsubir != '') this.data.img = this.imgsubir;

    if (this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/usuarios-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/usuarios-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          if (data.value == 'Email repetido') {
            swal('Email existente', 'Existe un usuario con este email', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        swal('Guardado', 'Datos guardados correctamente', 'success');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }
  
  AddCodPostal() {
    $('#modal-crear-codigo-postal').modal('show');
    $('#modal-crear-codigo-postal .modal-title').html('Nuevo código postal');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    this.dataitem.id_usuario = this.data.id;
    if (this.codigos_postales.length) this.dataitem.codpostal = this.codigos_postales[0].codpostal;
    // setTimeout(() => {
    //   $('#modal-crear-codigo-postal input[type="number"]')[0].focus();
    // }, 500);
  }
  EditCodPostal(item:any) {
    $('#modal-crear-codigo-postal').modal('show');
    $('#modal-crear-codigo-postal .modal-title').html('Editar código postal');
    this.dataitem = JSON.parse(JSON.stringify(item));
    this.dataitem.id_usuario = this.data.id;
    // setTimeout(() => {
    //   $('#modal-crear-codigo-postal input[type="number"]')[0].focus();
    // }, 500);
  }
  DelCodPostal(item:any) {
    if (!item || !item.id) {
      this.data.codigos_postales.splice(this.data.codigos_postales.findIndex(el => el.codpostal == item.codpostal), 1);
      return;
    }
    swal({
      title: "¿Borrar código postal?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/usuarios-del-codpostal', {id_usuario: item.id_usuario, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.codigos_postales.splice(this.data.codigos_postales.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  GuardarCodPostal() {
    if (
          this.dataitem.codpostal == ''
      || !this.dataitem.codpostal
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    let codpostal = $('#cmb-codpostal-edit option:selected').text();
    if (codpostal) this.dataitem.localidad = codpostal.split(' - ')[1];

    if (!this.data.id || this.data.id == '') {
      if (!this.data.codigos_postales) this.data.codigos_postales = [];
      this.data.codigos_postales.push({codpostal: this.dataitem.codpostal, localidad: this.dataitem.localidad});
      $('#modal-crear-codigo-postal').modal('hide');
      return;
    }

    if (!this.dataitem.id || this.dataitem.id == '') {
      $.buttonloading('btn-guardar-codpostal', 'Enviando');
      this.globals.Api('/usuarios-add-codpostal', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-codpostal');
        if (!data || data.error) {
          if (data.value == 'Repetido') {
            swal('Código Postal ya asignado', 'Este código postal ya ha sido asignado al técnico', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-codigo-postal').modal('hide');
        if (!this.data.codigos_postales) this.data.codigos_postales = [];
        this.data.codigos_postales.push({id_usuario: this.dataitem.id_usuario, id: data.value, codpostal: this.dataitem.codpostal, localidad: this.dataitem.localidad});
      }, error => {
        $.buttoncancelloading('btn-guardar-codpostal');
      });
    } else {
      $.buttonloading('btn-guardar-codpostal', 'Enviando');
      this.globals.Api('/usuarios-edit-codpostal', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-codpostal');
        if (!data || data.error) {
          if (data.value == 'Repetido') {
            swal('Código Postal ya asignado', 'Este código postal ya ha sido asignado al técnico', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        $('#modal-crear-codigo-postal').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-codpostal');
      });
    }
  }

  AddFranjaHoraria() {
    $('#modal-crear-franja-horaria').modal('show');
    $('#modal-crear-franja-horaria .modal-title').html('Nueva franja horaria');
    Object.keys(this.dataitem).forEach(item => {this.dataitem[item] = ''});
    this.dataitem.id_usuario = this.data.id;
    this.dataitem.disponible = 1;
    setTimeout(() => {
      $('#modal-crear-franja-horaria input[type="time"]')[0].focus();
    }, 500);
  }
  EditFranjaHoraria(item:any) {
    $('#modal-crear-franja-horaria').modal('show');
    $('#modal-crear-franja-horaria .modal-title').html('Editar código postal');
    this.dataitem = JSON.parse(JSON.stringify(item));
    this.dataitem.id_usuario = this.data.id;
    setTimeout(() => {
      $('#modal-crear-franja-horaria input[type="time"]')[0].focus();
    }, 500);
  }
  DelFranjaHoraria(item:any) {
    if (!item || !item.id) {
      this.data.franjas_horarias.splice(this.data.franjas_horarias.findIndex(el => el.codpostal == item.codpostal), 1);
      return;
    }
    swal({
      title: "¿Borrar franja horaria?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/usuarios-del-franja-horaria', {id_usuario: item.id_usuario, id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.data.franjas_horarias.splice(this.data.franjas_horarias.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  GuardarFranjaHora() {
    if (
          this.dataitem.hora == ''
      || !this.dataitem.hora
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    if (!this.data.id || this.data.id == '') {
      if (!this.data.franjas_horarias) this.data.franjas_horarias = [];
      this.data.franjas_horarias.push({hora: this.dataitem.hora, disponible: this.dataitem.disponible});
      $('#modal-crear-franja-horaria').modal('hide');
      return;
    }

    if (!this.dataitem.id || this.dataitem.id == '') {
      $.buttonloading('btn-guardar-franja-horaria', 'Enviando');
      this.globals.Api('/usuarios-add-franja-horaria', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-franja-horaria');
        if (!data || data.error) {
          if (data.value == 'Repetido') {
            swal('Franja horaria ya asignada', 'Esta franja horaria ya ha sido asignada al técnico', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        $('#modal-crear-franja-horaria').modal('hide');
        if (!this.data.franjas_horarias) this.data.franjas_horarias = [];
        this.data.franjas_horarias.push({id_usuario: this.dataitem.id_usuario, id: data.value, hora: this.dataitem.hora, disponible: this.dataitem.disponible});
      }, error => {
        $.buttoncancelloading('btn-guardar-franja-horaria');
      });
    } else {
      $.buttonloading('btn-guardar-franja-horaria', 'Enviando');
      this.globals.Api('/usuarios-edit-franja-horaria', this.dataitem).subscribe(data => {
        $.buttoncancelloading('btn-guardar-franja-horaria');
        if (!data || data.error) {
          if (data.value == 'Repetido') {
            swal('Franja horaria ya asignada', 'Esta franja horaria ya ha sido asignada al técnico', 'error');return;
          }
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Datos guardados correctamente`, 'Guardado');
        $('#modal-crear-franja-horaria').modal('hide');
      }, error => {
        $.buttoncancelloading('btn-guardar-franja-horaria');
      });
    }
  }
  SetFranjaDisponible(item:any) {
    if (!item) return;
    item.disponible = (item.disponible ? 1 : 0);
    if (!this.data.id || this.data.id == '') return;
    this.globals.Api('/usuarios-franja-horaria-set-disponible', {id: item.id, id_usuario: this.data.id, disponible: item.disponible}).subscribe(data => {
      if (!data || data.error) {
        item.disponible = !item.disponible;
        toastr.error(`Se ha producido un error`, 'Error');return;
      }
    }, error => {
      item.disponible = !item.disponible;
      toastr.error(`Se ha producido un error`, 'Error');
    });
  }

  CargarGrafica(){
    const labels = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];
  
    const data = {
      labels: labels,
      datasets: [{
        label: 'Reservas',
        fill: false,
        backgroundColor: '#7AAC3F',
        // borderColor: 'rgb(255, 99, 132)',
        borderColor: '#707070',
        data: [
          this.reservas.total_reservas_enero,
          this.reservas.total_reservas_febrero,
          this.reservas.total_reservas_marzo,
          this.reservas.total_reservas_abril,
          this.reservas.total_reservas_mayo,
          this.reservas.total_reservas_junio,
          this.reservas.total_reservas_julio,
          this.reservas.total_reservas_agosto,
          this.reservas.total_reservas_septiembre,
          this.reservas.total_reservas_octubre,
          this.reservas.total_reservas_noviembre,
          this.reservas.total_reservas_diciembre],
        pointRadius: 8,
      }]
    };
  
    const config = {
      type: 'line',
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    };
    if(this.myChart){
      //this.myChart.chart(config);
      const data = {
        labels: labels,
        datasets: [{
          label: 'Reservas',
          fill: false,
          backgroundColor: '#DB0033',
          // borderColor: 'rgb(255, 99, 132)',
          borderColor: '#707070',
          data: [
            this.data.total_reservas_enero,
            this.data.total_reservas_febrero,
            this.data.total_reservas_marzo,
            this.data.total_reservas_abril,
            this.data.total_reservas_mayo,
            this.data.total_reservas_junio,
            this.data.total_reservas_julio,
            this.data.total_reservas_agosto,
            this.data.total_reservas_septiembre,
            this.data.total_reservas_octubre,
            this.data.total_reservas_noviembre,
            this.data.total_reservas_diciembre],
          pointRadius: 8,
        }]
      };
      this.myChart.data.datasets.forEach(element => {
        element.data.push(data);
      });
      this.myChart.update();
    }else{
      this.myChart = new Chart(
        document.getElementById('myChart'),
        config
      );
    }
  }
  FiltrarMesAnio(){
    var mesSelect = $('#select-mes').val();
    var anioSelect = $('#select-anio').val();
    if(mesSelect == 'Selecciona un mes' || anioSelect == 'Selecciona un año'){
      swal('Error', 'Debes seleccionar un mes y año para poder filtrar', 'error');return;
    }
    this.globals.Api('/datos-generales-colaborador-filtro', {id_colaborador: this.data.id, mes_select: mesSelect, anio_select: anioSelect}).subscribe(data => {
      if (!data) return;
      this.reservasTarjetas = data;

    });
  }
  ExportarExcel(){
    var mesSelect = $('#select-mes').val();
    var anioSelect = $('#select-anio').val();
    if(mesSelect == 'Selecciona un mes' || anioSelect == 'Selecciona un año'){
      swal('Error', 'Debes seleccionar un mes y año para poder filtrar', 'error');return;
    }
    this.globals.ApiDownload('/datos-generales-colaborador-excel', {id_colaborador: this.data.id, mes_select: mesSelect, anio_select: anioSelect}).subscribe(data => {
      if (!data) return;
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'Reservas '+this.data.nombre +' '+ this.data.apellidos +' '+ mesSelect +'-'+ anioSelect + '.xlsx';
      document.body.appendChild(a);
        a.click();
      });
  }

}
