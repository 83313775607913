import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let tinymce: any;
declare let toastr: any;
declare let ICAL: any;
declare let L: any;

@Component({
  selector: 'app-vehiculos',
  templateUrl: './vehiculos.component.html',
  styleUrls: ['./vehiculos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VehiculosComponent implements OnInit {
  
  public vehiculos = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals,
    public sanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
  }

  

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.vehiculos = this.globals.GetData();
    this.globals.Api('/eve-vehiculos', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.vehiculos = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.vehiculos = data;
      this.appref.tick();
      if (!paginationload) return;
      this.globals.Api('/eve-vehiculos/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/vehiculos/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/vehiculos/edit')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar vehículo?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-vehiculos-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.vehiculos.splice(this.vehiculos.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID Alojamiento: ${item.id}`,'Copiado al portapapeles');
  }
  Duplicar(id_evento:any){
    swal({
      title: "¿Duplicar vehículo?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-vehiculos-duplicate', {id_evento: id_evento}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.appref.tick();
        this.CargarGrid();
      });
    });
  }

}
