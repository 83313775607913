<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-xs-12 my-auto">
                            <div class="header-section">
                                <h1 class="middle">Actividades</h1>
                                <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button>
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Actividades</li>
                            </ul>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center no-wrap">
                          <label class="col-form-label inline-block m-r-10">Mostrar</label>
                          <select class="form-control inline-block" (change)="Buscar()" [(ngModel)]="params.page_size" style="width:auto;">
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>200</option>
                          </select>
                        </div>
                        <div class="col-lg-5 col-md-6 col-xs-12 my-auto text-center">
                          <div class="input-group width-100">
                            <input type="text" placeholder="Buscar" class="form-control" (keyup.enter)="Buscar()" [(ngModel)]="params.filtro">
                            <div class="input-group-append cursor" (click)="Buscar()">
                              <span class="input-group-addon"><i class="fa fa-search"></i></span>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-xs-12 my-auto text-center hide-tablet">
                          <a class="btn btn-default btn-block" (click)="Nuevo()"><i class="fa fa-plus"></i> Nueva actividad</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                  <div class="block-title">
                    <!-- <div class="block-options pull-right">
                        <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                            style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                class="fa fa-cog"></i></a>
                    </div> -->
                    <ul class="nav nav-tabs" data-toggle="tabs">
                        <li class="active"><a href="#tab-actividades">Actividades</a></li>
                        <li><a href="#tab-gestion-aforo"><i class="icofont-users"></i> Gestión plazas</a></li>
                        <!-- <li><a href="#tab-tarifas"><i class="icofont-credit-card"></i> Tarifas</a></li> -->
                        <!-- <li><a href="#block-tabs-settings" data-toggle="tooltip" title="" data-original-title="Settings"><i class="gi gi-settings"></i></a></li> -->
                    </ul>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane active" id="tab-actividades">
                      <!-- Table Styles Content -->
                      <div class="table-grid block full break-padding">
                        <!--
                        Available Table Classes:
                            'table'             - basic table
                            'table-bordered'    - table with full borders
                            'table-borderless'  - table with no borders
                            'table-striped'     - striped table
                            'table-condensed'   - table with smaller top and bottom cell padding
                            'table-hover'       - rows highlighted on mouse hover
                            'table-vcenter'     - middle align content vertically
                        -->
                        <table class="table table-striped table-bordered table-vcenter table-hover">
                            <thead>
                                <tr>
                                  <!-- <th style="width:50px;" class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></th> -->
                                  <th style="width:50px"></th>
                                  <th style="width:50px">ID</th>
                                  <th order="nombre">Nombre</th>
                                  <th style="width:80px">Color</th>
                                  <th style="width:130px" order="alta">Alta</th>
                                  <th style="width:80px" order="aforo">Plazas</th>
                                  <!-- <th style="width:80px" order="tamano">Tama&ntilde;o</th> -->
                                  <th style="width:50px">Duplicar</th>
                                  <th style="width:50px">Editar</th>
                                  <th style="width:50px">Borrar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of actividades" class="cursor">
                                  <!-- <td class="text-center"><label class="csscheckbox csscheckbox-primary"><input type="checkbox"><span></span></label></td> -->
                                  <td class="text-center" (click)="Editar(item)">
                                    <img *ngIf="item.imagen" [src]="item.imagen" class="img-listado square">
                                    <i *ngIf="!item.imagen || item.imagen == ''" class="fa fa-user fa-2x"></i>
                                  </td>
                                  <td>{{item.id}}</td>
                                  <td class="font-bold text-primary" (click)="Editar(item)">
                                    <div>{{item.nombre}}
                                      <a class="btn btn-default btn-xs hide" (click)="Portapapeles($event, item)"><i class="fa fa-clipboard text-white"></i></a>
                                    </div>
                                  </td>
                                  <td><div class="distintivo-color" [style.background-color]="item.distintivo_color"></div></td>
                                  <td>{{item.alta_format}}</td>
                                  <td>{{item.aforo_predeterminado}}</td>
                                  <!-- <td><div *ngIf="item.tamano">{{item.tamano}} m<sup>2</sup></div></td> -->
                                  <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Duplicar(item.id)"><i class="fa fa-clone text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-primary btn-xs" (click)="Editar(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="Borrar(item)"><i class="fa fa-trash text-white"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="dataTables_paginate paging_simple_numbers m-r-10">
                          <ul class="pagination justify-content-end">
                            <li class="paginate_button page-item previous {{params.page_index <= 1 ? 'disabled' : ''}}">
                              <a (click)="Paging('prev')" class="page-link">Anterior</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index > 1">
                              <a (click)="Paging('prev')" class="page-link">{{params.page_index-1}}</a>
                            </li>
                            <li class="paginate_button page-item active">
                              <a class="page-link cursor">{{params.page_index}}</a>
                            </li>
                            <li class="paginate_button page-item" *ngIf="params.page_index < params.pages">
                              <a (click)="Paging('next')" class="page-link">{{params.page_index+1}}</a>
                            </li>
                            <li class="paginate_button page-item next {{params.page_index >= params.pages ? 'disabled' : ''}}">
                              <a (click)="Paging('next')" class="page-link">Siguiente</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- Tab gestión aforo-->
                    <div class="tab-pane" id="tab-gestion-aforo">
                      <div class="row">
                        <div class="col-lg-4 col-lg-push-8 col-md-12 col-xs-12">
                          <div class="block full calendar-container">
                            <div class="block-title">
                                <h2>Calendario</h2>
                            </div>
                            <div id="calendar" class="calendario-listado-reservas"></div>
                            <br>
                            <div class="row">
                              <div class="col-lg-6">
                                <div class="content-cerrar-todas-actividades">
                                  <button class="btn btn-danger" (click)="cerrarActividadesFecha()" style="font-size: 13px;
                                  padding-left: 10px;">Cerrar actividades</button>
                                  <p style="font-size: 14px !important;
                                  font-style: italic;
                                  line-height: 1.2em;
                                  margin-top: 7px;">*Cierra las actividades del día seleccionado</p>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="content-abrir-todas-actividades">
                                  <button class="btn btn-success" (click)="abrirActividadesFecha()" style="font-size: 13px;
                                  padding-left: 10px; text-shadow: 0px 0px 2px #9f9f9f;">Abrir actividades</button>
                                  <p style="font-size: 14px !important;
                                  font-style: italic;
                                  line-height: 1.2em;
                                  margin-top: 7px;">*Abre las actividades del día seleccionado</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="block full calendar-container">
                            <div class="block-title">
                                <h2>Bloquear por rangos de fecha</h2>
                            </div>
                            <div class="content-input-fechas">
                              <div class="row">
                                <div class="col-lg-12">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <label>Fecha inicio</label>
                                    </div>
                                    <div class="col-lg-6">
                                      <input id="fecha-inicio-bloqueo" class="form-control" type="date" [(ngModel)]="inputFechaInicioBloqueo">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12">
                                  <div class="row">
                                    <div class="col-lg-6">
                                      <label>Fecha fin</label>
                                    </div>
                                    <div class="col-lg-6">
                                      <input id="fecha-fin-bloqueo" min="{{inputFechaInicioBloqueo}}" class="form-control" type="date" [(ngModel)]="inputFechaFinBloqueo">
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-12">
                                  <button type="button" class="btn btn-primary" (click)="GuardarRangosFecha()">Bloquear</button>
                                </div>
                              </div>
                            </div>
                            <div class="content-table-rago-fechas margin-top-10">
                              <table class="table table-striped table-bordered table-vcenter table-hover">
                                <thead>
                                    <tr>
                                      <th>Fecha inicio</th>
                                      <th>Fecha fin</th>
                                      <th style="width:50px">Borrar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of arrRangosFechasBloqueo" class="cursor">
                                      <td class="font-bold text-primary">
                                          <div [innerHTML]="item.fecha_inicio_format"></div>
                                      </td>
                                      <td class="font-bold text-primary">
                                          <div [innerHTML]="item.fecha_fin_format"></div>
                                      </td>
                                      <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="EliminarRangosFecha(item.id)"><i class="fa fa-trash text-white"></i></a></td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-8 col-lg-pull-4 col-md-12 col-xs-12">

                          <div class="row">
                            <div class="col-lg-4">
                              <div class="row list-actividades-gestion-aforo">
                                <div class="col-lg-12 content-actividades-gestion-aforo" style="padding-bottom: 10px;" *ngFor="let actividad of actividades">
                                  <a id="{{actividad.id}}" class="btn row" (click)="SeleccionarActividad(actividad.id)">
                                    <img *ngIf="actividad.imagen" class="col-lg-3 col-md-3 col-xs-3" src="{{actividad.imagen}}" />
                                    <div class="col-lg-9 col-md-9 col-xs-9 nombre-actividad"><p class="wrap">{{actividad.nombre}}</p><input type="checkbox" val="{{actividad.id}}" style="display: none;"/></div>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-8">
                              <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" style="padding: 0 10px;" *ngFor="let aforo of aforoV2">
                                  <a href="javascript:void(0)" class="reserva-restaurante widget">
                                    <div class="widget-content text-right clearfix">
                                      <div class="pull-left icon-tipo text-left" style="width: 38%;">
                                        <!-- <span class="badge-reserva">
                                          <img src="assets/icon-ref-admin.svg">
                                          Admin 
                                        </span> -->
                                        <h5 class="text-left h4 content-fecha">Fecha: <strong>{{aforo.fecha_format}}</strong></h5>
                                        <h5 class="text-left h4 content-sesion">Sesion: <strong>{{aforo.sesion}}</strong></h5>
                                        <h5 class="text-left h4 content-sesion btn btn-warning" *ngIf="aforo.plazas_adicionales != 0 && aforo.plazas_adicionales"><strong>Editado</strong></h5>
                                        <!-- <div *ngIf="item.ocultar == 1" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="{{item.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="item.percentage+'%'"></div> -->
                                      </div>
                                      <div class="h4 content-n-personas"><strong>{{aforo.personas}}</strong> personas</div>
                                      <h5 class="h5 aforo-total">Plazas total: <strong>{{aforo.aforo_predeterminado}}</strong></h5>
                                      <h5 class="h5 aforo-disponible">Plazas disponible: <strong>{{aforo.aforo_disponible}}</strong></h5>
                                      <div class="m-t-10">
                                        <a *ngIf="!aforo.cerrado" class="btn btn-sm btn-success" (click)="BloquearDiaSesion(aforo)"><i class="fa fa-unlock font-14"></i></a>
                                        <a *ngIf="aforo.cerrado" class="btn btn-sm btn-danger" (click)="DesbloquearDiaSesion(aforo)"><i class="fa fa-lock font-14"></i></a>
                                        <a class="btn btn-sm btn-warning" (click)="EditarPlazasAdicionales(aforo)" style="margin-left: 5px;"><i class="fa fa-pencil font-14"></i></a>
                                      </div>
                                    </div>
                                    <div class="progress progress-mini active custom-progress-bar-red" style="height: 12px;
                                        line-height: 12px;
                                        width: 90%;
                                        margin-bottom: 0px;
                                        margin-top: 4px;
                                        margin-left: 20px;">
                                      <div *ngIf="!aforo.cerrado"class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="{{aforo.porcentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="aforo.porcentage+'%'"></div>
                                      <div *ngIf="aforo.cerrado" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="{{aforo.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="aforo.percentage+'%'"></div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<!-- Modal reserva desde calendario -->
<div class="modal inmodal fade" id="modal-editar-plazas" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" style="font-size: 35px;" (click)="CerrarModalPlazasAdicionales()"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
              <h3 class="modal-title" style="text-align: center;"><i class="icofont-ticket"></i> Editar plazas</h3>
          </div>
        <div class="row modal-body" style="text-align: center;">
          <h4>Introduce el número de plazas</h4>
          <input class="form-control" type="number" [(ngModel)]="plazasAdicionales" style="max-width: 100px; margin: auto; text-align: center;"/><br>
          <span>*Dejar en blanco para establecer las plazas por defecto</span>
        </div>  
        <div class="modal-footer col-md-12 col-xs-12">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button type="button" class="btn btn-primary" id="btn-guardar-reserva" (click)="GuardarPlazasAdicionales()">Guardar</button>
        </div>
      </div>
  </div>
</div>