<div id="page-wrapper" class="page-loading">
  <div class="preloader">
      <div class="inner">
          <!-- Animation spinner for all modern browsers -->
          <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

          <!-- Text for IE9 -->
          <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
      </div>
  </div>
  <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
      <!-- Main Sidebar -->
      <app-backend-menu></app-backend-menu>
      <!-- END Main Sidebar -->

      <!-- Main Container -->
      <div id="main-container">
          <!-- Header -->
          <app-backend-header></app-backend-header>
          <!-- END Header -->

          <!-- Page content -->
          <div id="page-content" class="add-edit-actividad-page">
              <div class="content-header">
                  <div class="row-equal-cols">
                      <div class="col-lg-8 col-md-6 col-xs-12">
                          <div class="header-section">
                              <h1 class="middle">{{title}}</h1>
                              <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                          </div>
                          <ul class="breadcrumb breadcrumb-top text-left">
                              <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                              <li><a [routerLink]="['/actividades']">Actividades</a></li>
                              <li>{{title}}</li>
                          </ul>
                      </div>
                      <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                        <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                        <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                      </div>
                  </div>
              </div>

              <div class="block full break-padding">
                  <div class="block-title">
                      <!-- <div class="block-options pull-right">
                          <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                              style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                  class="fa fa-cog"></i></a>
                      </div> -->
                      <ul class="nav nav-tabs" data-toggle="tabs">
                          <li class="active"><a href="#tab-general">Datos generales</a></li>
                          <li><a href="#tab-aforo-v2"><i class="icofont-users"></i> Aforo</a></li>
                          <li *ngIf="data.id"><a href="#tab-vehiculos"><i class="icofont-car"></i> Vehículos</a></li>
                          <li><a href="#tab-calendario"><i class="icofont-ui-calendar"></i> Calendario</a></li>
                          <!-- <li><a href="#tab-tarifas"><i class="icofont-credit-card"></i> Tarifas</a></li> -->
                          <!-- <li><a href="#block-tabs-settings" data-toggle="tooltip" title="" data-original-title="Settings"><i class="gi gi-settings"></i></a></li> -->
                      </ul>
                  </div>
                  <div class="tab-content">
                      <div class="tab-pane active" id="tab-general">

                          <div class="row">
                            <div class="col-lg-4 col-md-12">
                              <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                                <i class="icofont-camera-alt font-50"></i>
                              </div>
                              <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                                <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                                <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                                <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                              </div>
                              <hr>
                              <h4 class="hide">Galería de imágenes</h4>
                              <div class="text-right hide" *ngIf="!data.imagenes || data.imagenes.length <= 20" style="margin-top: -30px;margin-bottom: 20px;">
                                <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen a la galería<a href=""></a></a>
                              </div>
                              <div class="text-center hide" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                                <h4>No hay imágenes aún</h4>
                              </div>
                              <div class="row gallery hide" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes" style="padding: 0px 10px;">
                                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-xs-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id" style="padding-left:5px;padding-right:5px;">
                                    <div class="gallery-image-container animation-fadeInQuick2Inv" style="margin-bottom: 10px;">
                                      <img src="{{item.imagen}}" alt="Image">
                                      <a href="{{item.imagen}}" class="gallery-image-options" data-toggle="lightbox-image" title="Image Info">
                                        <h2 class="text-light" style="margin:0;font-size:12px"><strong>Ampliar</strong></h2>
                                        <i class="fa fa-search-plus fa-3x text-light" style="font-size:12px"></i>
                                      </a>
                                      <a class="btn btn-danger cursor absolute" style="top:0;right:0;" (click)="DelImagen($event, item)"><i class="fa fa-trash"></i></a>
                                    </div>
                                </div>
                              </div>
                              <hr>
                              <h4>Características</h4>
                              <div class="text-right" *ngIf="!data.caracteristicas || data.caracteristicas.length <= 20"  style="margin-top: -30px;margin-bottom: 20px;">
                                <a class="btn btn-primary m-l-20" (click)="AddCaracteristica()">Añadir característica</a>
                              </div>
                              <div class="table-responsive table-grid">
                                  <table class="table table-striped table-bordered table-vcenter table-hover">
                                      <thead>
                                          <tr>
                                            <th style="width:50px"></th>
                                            <th style="width:50px">Icono</th>
                                            <th>Característica</th>
                                            <th style="width:50px">Borrar</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let item of data.caracteristicas" class="cursor" [attr.itemid]="item.id">
                                            <td><i class="fa fa-sort"></i></td>
                                            <td><i class="{{item.icono}}"></i></td>
                                            <td class="font-bold text-primary" (click)="EditCaracteristica(item)">
                                                <div [innerHTML]="item.caracteristica"></div>
                                            </td>
                                            <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelCaracteristica(item)"><i class="fa fa-trash text-white"></i></a></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <hr>
                              <h4>Categorías</h4>
                              <div class="text-right" *ngIf="!data.categorias || data.categorias.length <= 20"  style="margin-top: -30px;margin-bottom: 20px;">
                                <a class="btn btn-primary m-l-20" (click)="AddCategoria()">Añadir categoría</a>
                              </div>
                              <div class="table-responsive table-grid">
                                  <table class="table table-striped table-bordered table-vcenter table-hover">
                                      <thead>
                                          <tr>
                                            <th style="width:50px"></th>
                                            <th style="width:50px">Icono</th>
                                            <th>Categoría</th>
                                            <th style="width:50px">Borrar</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          <tr *ngFor="let item of data.categorias" class="cursor" [attr.itemid]="item.id">
                                            <td><i class="fa fa-sort"></i></td>
                                            <td><i class="{{item.icono}}"></i></td>
                                            <td class="font-bold text-primary" (click)="EditCategoria(item)">
                                                <div [innerHTML]="item.categoria"></div>
                                            </td>
                                            <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelCategoria(item)"><i class="fa fa-trash text-white"></i></a></td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                              <div id="content-iframe-gmaps">
                                <iframe *ngIf="data.localizacion_maps" width="100%" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&height=600&hl=es&t=&z=14&ie=UTF8&iwloc=B&output=embed" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
                              </div>
                            </div>
                            <div class="col-lg-8 col-md-12">

                              <div class="row">
                                <div class="col-md-7 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary">Nombre *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                  </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary">Activa WEB *</label>
                                    <select class="form-control" [(ngModel)]="data.web">
                                      <option value="1">Activa</option>
                                      <option value="0">Desactivada</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-2 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary">Distintivo *</label>
                                    <input type="color" class="form-control" [(ngModel)]="data.distintivo_color">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 col-xs-12">
                                  <div class="row">
                                    <div class="col-xs-12 col-lg-12">
                                      <div class="form-group">
                                        <label style="cursor: pointer;"><input type="checkbox" [(ngModel)]="data.colaborador"/> ¿Permitir al colaborador reservar esta actividad?</label><br>
                                      </div>
                                    </div>
                                    <div class="col-xs-12 {{data.permanente ? 'col-lg-8' : 'col-lg-12'}}">
                                      <div class="form-group">
                                        <label style="cursor: pointer;"><input type="checkbox" [(ngModel)]="data.permanente" (click)="SetPermanente()"/> Actividad permanente durante todo el año</label><br>
                                        <label class="text-primary">Descripci&oacute;n *</label>
                                        <textarea id="txtdescripcion" type="text" class="form-control text-editor" placeholder="Escriba aqu&iacute; la descripci&oacute;n" [(ngModel)]="data.descripcion" maxlength="5000" rows="4"></textarea>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-xs-12" *ngIf="data.permanente">
                                      <label>Sesiones</label>
                                      <div class="text-right content-buttton-add-sesion" *ngIf="!sesiones || sesiones.length <= 20">
                                        <a class="btn btn-primary m-l-20 button-add-sesion" (click)="AddSesion()">Añadir sesión</a>
                                      </div>
                                      <div class="table-responsive table-grid">
                                        <table class="table table-striped table-bordered table-vcenter table-hover">
                                            <thead>
                                                <tr>
                                                  <th style="width:50px"></th>
                                                  <th>Sesión</th>
                                                  <th style="width:50px">Borrar</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of sesiones" class="cursor">
                                                  <td><i class="icofont-clock-time"></i></td>
                                                  <td class="font-bold text-primary" (click)="EditSesion(item.sesiones)">
                                                      <div [innerHTML]="item.sesiones"></div>
                                                  </td>
                                                  <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelSesion(item)"><i class="fa fa-trash text-white"></i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <!-- <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Adultos *</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.adultos" maxlength="10">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Ni&ntilde;os</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.ninos" maxlength="10">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Número mínimo noches para reservar *</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.num_minimo_noches" maxlength="10">
                                  </div>
                                </div> -->
                                <div class="col-xl-6 col-lg-12 col-md-12 col-xs-12">
                                  <div class="form-group row">
                                    <div id="content-check-days" class="col-lg-12">
                                        <label class="switch switch-success m-r-5">Lunes<br><input type="checkbox" dia="LU" class="chk-dia-temporada" [(ngModel)]="data.lunes"><span></span></label>
                                        <label class="switch switch-success m-r-5">Martes<br><input type="checkbox" dia="MA" class="chk-dia-temporada" [(ngModel)]="data.martes"><span></span></label>
                                        <label class="switch switch-success m-r-5">Miércoles<br><input type="checkbox" dia="MI" class="chk-dia-temporada" [(ngModel)]="data.miercoles"><span></span></label>
                                        <label class="switch switch-success m-r-5">Jueves<br><input type="checkbox" dia="JU" class="chk-dia-temporada" [(ngModel)]="data.jueves"><span></span></label>
                                        <label class="switch switch-success m-r-5">Viernes<br><input type="checkbox" dia="VI" class="chk-dia-temporada" [(ngModel)]="data.viernes"><span></span></label>
                                        <label class="switch switch-success m-r-5">Sábado<br><input type="checkbox" dia="SA" class="chk-dia-temporada" [(ngModel)]="data.sabado"><span></span></label>
                                        <label class="switch switch-success m-r-5">Domingo<br><input type="checkbox" dia="DO" class="chk-dia-temporada" [(ngModel)]="data.domingo"><span></span></label>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Plazas</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.aforo_predeterminado" maxlength="3">
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Plazas mínimas</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.aforo_minimo" maxlength="3">
                                  </div>
                                </div>
                                <div class="col-xl-5 col-lg-5 col-md-5 col-xs-12">
                                  <div class="form-group">
                                    <label>Punto de salida</label>
                                    <input type="text" placeholder="Sin coordenadas" class="form-control"  (click)="AbrirMapa()" [(ngModel)]="data.localizacion_maps" maxlength="400">
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Código postal</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.cod_postal" maxlength="5" (change)="SetLocalidad()">
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Localidad</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="50">
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-4 col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Provincia</label>
                                    <!-- <input type="text" class="form-control" [(ngModel)]="data.provincia"/> -->
                                    <select required name="provincia" class="form-control" [(ngModel)]="data.provincia">
                                      <option value="">Elige Provincia</option>
                                      <option value="Álava/Araba">Álava/Araba</option>
                                      <option value="Albacete">Albacete</option>
                                      <option value="Alicante">Alicante</option>
                                      <option value="Almería">Almería</option>
                                      <option value="Asturias">Asturias</option>
                                      <option value="Ávila">Ávila</option>
                                      <option value="Badajoz">Badajoz</option>
                                      <option value="Baleares">Baleares</option>
                                      <option value="Barcelona">Barcelona</option>
                                      <option value="Burgos">Burgos</option>
                                      <option value="Cáceres">Cáceres</option>
                                      <option value="Cádiz">Cádiz</option>
                                      <option value="Cantabria">Cantabria</option>
                                      <option value="Castellón">Castellón</option>
                                      <option value="Ceuta">Ceuta</option>
                                      <option value="Ciudad Real">Ciudad Real</option>
                                      <option value="Córdoba">Córdoba</option>
                                      <option value="Cuenca">Cuenca</option>
                                      <option value="Gerona/Girona">Gerona/Girona</option>
                                      <option value="Granada">Granada</option>
                                      <option value="Guadalajara">Guadalajara</option>
                                      <option value="Guipúzcoa/Gipuzkoa">Guipúzcoa/Gipuzkoa</option>
                                      <option value="Huelva">Huelva</option>
                                      <option value="Huesca">Huesca</option>
                                      <option value="Jaén">Jaén</option>
                                      <option value="La Coruña/A Coruña">La Coruña/A Coruña</option>
                                      <option value="La Rioja">La Rioja</option>
                                      <option value="Las Palmas">Las Palmas</option>
                                      <option value="León">León</option>
                                      <option value="Lérida/Lleida">Lérida/Lleida</option>
                                      <option value="Lugo">Lugo</option>
                                      <option value="Madrid">Madrid</option>
                                      <option value="Málaga">Málaga</option>
                                      <option value="Melilla">Melilla</option>
                                      <option value="Murcia">Murcia</option>
                                      <option value="Navarra">Navarra</option>
                                      <option value="Orense/Ourense">Orense/Ourense</option>
                                      <option value="Palencia">Palencia</option>
                                      <option value="Pontevedra">Pontevedra</option>
                                      <option value="Salamanca">Salamanca</option>
                                      <option value="Segovia">Segovia</option>
                                      <option value="Sevilla">Sevilla</option>
                                      <option value="Soria">Soria</option>
                                      <option value="Tarragona">Tarragona</option>
                                      <option value="Tenerife">Tenerife</option>
                                      <option value="Teruel">Teruel</option>
                                      <option value="Toledo">Toledo</option>
                                      <option value="Valencia">Valencia</option>
                                      <option value="Valladolid">Valladolid</option>
                                      <option value="Vizcaya/Bizkaia">Vizcaya/Bizkaia</option>
                                      <option value="Zamora">Zamora</option>
                                      <option value="Zaragoza">Zaragoza</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Precio adulto</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.precio" maxlength="50" />
                                  </div>
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Precio niños</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.precio_ninios" maxlength="50" />
                                  </div>
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Edad niños</label>
                                    <input type="number" class="form-control" [(ngModel)]="data.edad_ninios" maxlength="2" />
                                  </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Duración</label>
                                    <input type="number" placeholder="En minutos" class="form-control" [(ngModel)]="data.duracion" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12">
                                  <h4>Días y precios personalizables</h4>
                                </div>
                                <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12">
                                  <a id="nuevo-precio-especial" class="btn btn-primary m-l-20" (click)="AddDiaEspecial()">Añadir nueva tarifa</a>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12">
                                  <p *ngIf="!data.diasEspeciales">Sin precios ni días personalizados</p>
                                  <div id="content-table-special" class="" *ngIf="data.diasEspeciales">
                                    <br/>
                                    <table class="table table-striped table-bordered table-vcenter table-hover">
                                        <thead>
                                            <tr>
                                              <th>Concepto</th>
                                              <th>Fecha inicio</th>
                                              <th>Fecha fin</th>
                                              <th>Sesiones</th>
                                              <th>Precio adultos</th>
                                              <th>Precio niños</th>
                                              <th>Aforo</th>
                                              <th style="width:50px">Editar</th>
                                              <th style="width:50px">Borrar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of data.diasEspeciales" class="cursor">
                                              <td class="font-bold text-primary" (click)="EditDiaEspecial(item)">
                                                  <div [innerHTML]="item.concepto"></div>
                                              </td>
                                              <td class="font-bold text-primary" (click)="EditDiaEspecial(item)">
                                                  <div [innerHTML]="item.fecha_inicio_format"></div>
                                              </td>
                                              <td class="font-bold text-primary" (click)="EditDiaEspecial(item)">
                                                  <div [innerHTML]="item.fecha_fin_format"></div>
                                              </td>
                                              <td class="font-bold text-primary" (click)="EditDiaEspecial(item)">
                                                  <div [innerHTML]="item.sesionesFormat"></div>
                                              </td>
                                              <td class="font-bold text-primary" (click)="EditDiaEspecial(item)">
                                                  <div [innerHTML]="item.precio_adultos"></div>
                                              </td>
                                              <td class="font-bold text-primary" (click)="EditDiaEspecial(item)">
                                                  <div [innerHTML]="item.precio_ninios"></div>
                                              </td>
                                              <td class="font-bold text-primary" (click)="EditDiaEspecial(item)">
                                                  <div [innerHTML]="item.aforo"></div>
                                              </td>
                                              <td class="text-center btntd"><a class="btn btn-warning btn-xs" (click)="EditDiaEspecial(item)"><i class="fa fa-pencil text-white"></i></a></td>
                                              <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelDiaEspecial(item.id)"><i class="fa fa-trash text-white"></i></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 col-xs-12">
                                  <div class="block">
                                      <div class="block-title"><h2>Información adicional</h2></div>
                                      <div class="form-group row">
                                          <div class="col-lg-12">
                                              <textarea id="txtinfo" class="info-adicional text-editor" type="text" placeholder="Escriba aqu&iacute; la información adicional" [(ngModel)]="data.informacion_adicional" maxlength="10000" rows="7"> </textarea>
                                          </div>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </div>
                      <div class="tab-pane" id="tab-imagenes">
                        <!-- <div class="text-right" *ngIf="!data.imagenes || data.imagenes.length <= 20">
                            <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen</a>
                        </div>
                        <hr>
                        <div class="text-center" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                          <h4>No hay imágenes aún</h4>
                        </div>
                        <div class="row gallery" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes">
                          <div class="col-xl-3 col-md-4 col-sm-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id">
                              <div class="gallery-image-container animation-fadeInQuick2Inv">
                                <img src="{{item.imagen}}" alt="Image">
                                <a class="btn btn-danger cursor absolute" style="top:0;right:0" (click)="DelImagen(item)"><i class="fa fa-trash"></i> Borrar</a>
                              </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="tab-pane" id="tab-caracteristicas">

                        <!-- <div class="text-right" *ngIf="!data.caracteristicas || data.caracteristicas.length <= 20">
                            <a class="btn btn-primary m-l-20" (click)="AddCaracteristica()">Añadir característica</a>
                        </div>
                        <div class="table-responsive table-grid">
                            <table class="table table-striped table-bordered table-vcenter table-hover">
                                <thead>
                                    <tr>
                                      <th style="width:50px"></th>
                                      <th style="width:50px">Icono</th>
                                      <th>Característica</th>
                                      <th style="width:50px">Borrar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data.caracteristicas" class="cursor" [attr.itemid]="item.id">
                                      <td><i class="fa fa-sort"></i></td>
                                      <td><i class="{{item.icono}}"></i></td>
                                      <td class="font-bold text-primary" (click)="EditCaracteristica(item)">
                                          <div [innerHTML]="item.caracteristica"></div>
                                      </td>
                                      <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelCaracteristica(item)"><i class="fa fa-trash text-white"></i></a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->

                      </div>
                      <div class="tab-pane" id="tab-aforo-v2">
                        <!--<div class="text-right" *ngIf="!data.servicios || data.servicios.length <= 20">
                            <a class="btn btn-primary m-l-20" (click)="AddServicio()">Añadir servicio</a>
                        </div>-->
                        <div class="row">
                          <div class="col-lg-4 col-lg-push-8 col-md-12 col-xs-12">
                            <div class="block full calendar-container">
                                <div class="block-title">
                                    <h2>Calendario</h2>
                                </div>
                                <div id="calendar" class="calendario-listado-reservas"></div>
                            </div>
                            <div class="block full calendar-container">
                              <div class="block-title">
                                  <h2>Bloquear por rangos de fecha</h2>
                              </div>
                              <div class="content-input-fechas">
                                <div class="row">
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-6">
                                        <label>Fecha inicio</label>
                                      </div>
                                      <div class="col-lg-6">
                                        <input id="fecha-inicio-bloqueo" class="form-control" type="date" [(ngModel)]="inputFechaInicioBloqueo">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-6">
                                        <label>Fecha fin</label>
                                      </div>
                                      <div class="col-lg-6">
                                        <input id="fecha-fin-bloqueo" min="{{inputFechaInicioBloqueo}}" class="form-control" type="date" [(ngModel)]="inputFechaFinBloqueo">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12">
                                    <button type="button" class="btn btn-primary" (click)="GuardarRangosFecha()">Bloquear</button>
                                  </div>
                                </div>
                              </div>
                              <div class="content-table-rago-fechas margin-top-10">
                                <table class="table table-striped table-bordered table-vcenter table-hover">
                                  <thead>
                                      <tr>
                                        <th>Fecha inicio</th>
                                        <th>Fecha fin</th>
                                        <th style="width:50px">Borrar</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr *ngFor="let item of arrRangosFechasBloqueo" class="cursor">
                                        <td class="font-bold text-primary">
                                            <div [innerHTML]="item.fecha_inicio_format"></div>
                                        </td>
                                        <td class="font-bold text-primary">
                                            <div [innerHTML]="item.fecha_fin_format"></div>
                                        </td>
                                        <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="EliminarRangosFecha(item.id)"><i class="fa fa-trash text-white"></i></a></td>
                                      </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-8 col-lg-pull-4 col-md-12 col-xs-12">
                            <div class="row">
                              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;" *ngFor="let aforo of aforoV2">
                                <a href="javascript:void(0)" class="reserva-restaurante widget">
                                  <div class="widget-content text-right clearfix">
                                    <div class="pull-left icon-tipo text-left" style="width: 38%;">
                                      <!-- <span class="badge-reserva">
                                        <img src="assets/icon-ref-admin.svg">
                                        Admin
                                      </span> -->
                                      <h5 class="text-left h4">Fecha: <strong>{{aforo.fecha_format}}</strong></h5>
                                      <h5 class="text-left h4">Sesion: <strong>{{aforo.sesion}}</strong></h5>
                                      <!-- <div *ngIf="item.ocultar == 1" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="{{item.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="item.percentage+'%'"></div> -->
                                    </div>
                                    <div class="h4"><strong>{{aforo.personas}}</strong> personas</div>
                                    <h5 class="h5">Aforo total: <strong>{{aforo.aforo_predeterminado}}</strong></h5>
                                    <h5 class="h5">Aforo disponible: <strong>{{aforo.aforo_disponible}}</strong></h5>
                                    <div class="m-t-10">
                                      <a *ngIf="!aforo.cerrado" class="btn btn-sm btn-success" (click)="BloquearDiaSesion(aforo)"><i class="fa fa-unlock font-14"></i></a>
                                      <a *ngIf="aforo.cerrado" class="btn btn-sm btn-danger" (click)="DesbloquearDiaSesion(aforo)"><i class="fa fa-lock font-14"></i></a>
                                    </div>
                                  </div>
                                  <div class="progress progress-mini active custom-progress-bar-red" style="height: 12px;
                                      line-height: 12px;
                                      width: 90%;
                                      margin-bottom: 0px;
                                      margin-top: 4px;
                                      margin-left: 20px;">
                                    <div *ngIf="!aforo.cerrado"class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="{{aforo.porcentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="aforo.porcentage+'%'"></div>
                                    <div *ngIf="aforo.cerrado" class="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="{{aforo.percentage}}" aria-valuemin="0" aria-valuemax="100" style="height: 12px;line-height: 12px;" [style.width]="aforo.percentage+'%'"></div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div *ngIf="data.id" class="tab-pane" id="tab-vehiculos">
                        <div class="row">
                          <div class="col-lg-12">
                            <label style="cursor: pointer;"><input type="checkbox" [(ngModel)]="data.vehiculo" (click)="HabilitarVehiculos()"/> Gestión de vehículos</label><br>
                          </div>
                        </div>
                        <div class="row display-flex" *ngIf="data.vehiculo">
                          <div class="col-xl-3 col-lg-4 col-sm-5">
                            <h2 class="title-vehiculos">Vehículos disponibles</h2>
                            <ng_continer *ngFor="let vehiculo of vehiculos">
                              <div id="{{vehiculo.id}}"  class="row list-vehiculos" (click)=addIdVehiculoSelect(vehiculo.id)>
                                <div class="col-lg-2 col-sm-2"><i class="fa fa-car fa-2x"></i></div>
                                <div class="col-lg-9 col-sm-9"><p>{{vehiculo.nombre}}</p></div>
                                <div class="col-lg-1 col-sm-1"><input class="vehiculo-{{vehiculo.id}}" from="{{vehiculo.id}}" type="checkbox"/></div>
                              </div>
                            </ng_continer>
                          </div>
                          <div class="col-lg-3 col-sm-2 display-flex">
                            <div class="buttons-arrows">
                              <a class="btn" (click)="addVehiculos()"><i class="fa fa-arrow-right"></i></a><br>
                              <a class="btn" (click)="removeVehiculos()"><i class="fa fa-arrow-left"></i></a>
                            </div>
                          </div>
                          <div class="col-xl-3 col-lg-4 col-sm-5">
                            <h2 class="title-vehiculos">Vehículos usados para esta actividad</h2>
                            <div *ngIf="vehiculosSelect.length == 0">No hay vehículos seleccionados</div>
                            <ng-container *ngIf="vehiculosSelect.length != 0">
                              <div id="{{vehiculoSelect.id}}" class="row list-vehiculos" *ngFor="let vehiculoSelect of vehiculosSelect" (click)=removeIdVehiculoSelect(vehiculoSelect.id)>
                                <div class="col-lg-2 col-sm-2"><i class="fa fa-car fa-2x"></i></div>
                                <div class="col-lg-9 col-sm-9"><p>{{vehiculoSelect.nombre}}</p></div>
                                <div class="col-lg-1 col-sm-1"><input class="vehiculo-{{vehiculoSelect.id}}" type="checkbox"/></div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                        <!-- <div class="text-right" *ngIf="!data.imagenes || data.imagenes.length <= 20">
                            <a id="btn-add-imagen" class="btn btn-primary m-l-20" (click)="AddImagen()">Añadir imagen</a>
                        </div>
                        <hr>
                        <div class="text-center" *ngIf="!data || !data.imagenes || !data.imagenes.length">
                          <h4>No hay imágenes aún</h4>
                        </div>
                        <div class="row gallery" data-toggle="lightbox-gallery" *ngIf="data && data.imagenes">
                          <div class="col-xl-3 col-md-4 col-sm-6" *ngFor="let item of data.imagenes" [attr.itemid]="item.id">
                              <div class="gallery-image-container animation-fadeInQuick2Inv">
                                <img src="{{item.imagen}}" alt="Image">
                                <a class="btn btn-danger cursor absolute" style="top:0;right:0" (click)="DelImagen(item)"><i class="fa fa-trash"></i> Borrar</a>
                              </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="tab-pane" id="tab-calendario">
                        <div class="row">
                          <div class="col-md-7 col-xs-12">
                            <div class="pull-right" style="width: 40%;">
                              <h2>Legenda</h2>
                              <div class="legenda">
                                <div><span class="bloqueada airbnb"></span> AirBnb</div>
                              </div>
                            </div>
                            <h2>Fechas reservadas</h2>
                            <div id="calendario-cierres" class="calendario-open"></div>
                          </div>
                          <div class="col-md-5 col-xs-12">
                            <h2>Sincronización calendarios</h2>
                            <div class="form-group">
                              <label class="text-primary">URL Calendario Airbnb (.ics)</label>
                              <input type="url" class="form-control" [(ngModel)]="data.url_calendario_airbnb" maxlength="400">
                            </div>
                            <ng-container *ngIf="data && data.id && data.id != ''">
                              <hr>
                              <h4>URL calendario reservas exportado (.ics)</h4>
                              <a [href]="globals.api_url+'/export-ics/'+data.id" target="_blank" class="text-primary">{{globals.api_url+'/export-ics/'+data.id}}</a>
                            </ng-container>
                          </div>
                        </div>

                      </div>
              </div>

              <div class="col-botones">
                <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
              </div>

          </div>
          <!-- END Page Content -->
      </div>
      <!-- END Main Container -->
  </div>
  <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-crear-caracteristica" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5 col-xs-12">
            <div class="form-group">
              <label>Predefinidas</label><br>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-bars', 'Nivel')">
                <i class="icofont-bars"></i>
                Nivel
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-clock-time', 'Duración')">
                <i class="icofont-clock-time"></i>
                Duración
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CaracteristicaDefecto('icofont-car-alt-4', 'Con transporte')">
                <i class="icofont-car-alt-4"></i>
                Con transporte
              </div>
            </div>
          </div>
          <div class="col-md-7 col-xs-12">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Característica <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.caracteristica" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
                <div class="col-lg-8 chosen-iconos cmb-icono-caracteristica">
                  <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
                  </select>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-caracteristica" (click)="GuardarCaracteristica()">Guardar</button>
      </div>
      </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-crear-categoria" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5 col-xs-12">
            <div class="form-group">
              <label>Predefinidas</label><br>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CategoriaDefecto('icofont-car-alt-4', 'Rutas 4x4')">
                <i class="icofont-car-alt-4"></i>
                Rutas 4x4
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CategoriaDefecto('icofont-climbing', 'Barranquismo')">
                <i class="icofont-climbing"></i>
                Barranquismo
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CategoriaDefecto('icofont-tree', 'Agroturismo')">
                <i class="icofont-tree"></i>
                Agroturismo
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CategoriaDefecto('icofont-canoe', 'Kayak')">
                <i class="icofont-canoe"></i>
                Kayak
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CategoriaDefecto('icofont-canoe', 'Paddle surft')">
                <i class="icofont-canoe"></i>
                Paddle surft
              </div>
              <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CategoriaDefecto('icofont-binoculars', 'Birdwatching')">
                <i class="icofont-binoculars"></i>
                Birdwatching
              </div>
            </div>
            <div class="form-group">
              <label>Categorías usadas</label><br>
              <ng-container *ngFor="let categoria of categoriasUsadas">
                <div class="btn btn-default cursor" style="margin-top:10px;margin-right:10px;" (click)="CategoriaDefecto(categoria.icono,categoria.categoria)">
                  <i class="{{categoria.icono}}"></i>
                  {{categoria.categoria}}
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-md-7 col-xs-12">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Característica <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="dataitem.categoria" maxlength="50">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
                <div class="col-lg-8 chosen-iconos cmb-icono-categoria">
                  <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
                  </select>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-categoria" (click)="GuardarCategoria()">Guardar</button>
      </div>
      </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-crear-sesion" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Sesión <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="time" class="form-control" [(ngModel)]="sesion" maxlength="5">
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-sesion" (click)="GuardarSesion()">Guardar</button>
      </div>
      </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-crear-servicio" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Servicio <span class="text-danger">*</span></label>
            <div class="col-lg-8">
                <input type="text" class="form-control" [(ngModel)]="dataitem.servicio" maxlength="50">
            </div>
        </div>
        <div class="form-group row">
            <label class="col-lg-4 col-form-label">Icono <span class="text-danger">*</span></label>
            <div class="col-lg-8 chosen-iconos cmb-icono-servicio">
              <select class="form-control chosen-select" [(ngModel)]="dataitem.icono">
              </select>
            </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-servicio" (click)="GuardarServicio()">Guardar</button>
      </div>
      </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-crear-dia-especial" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
      <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title font-bold"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Fecha inicio:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="date" class="form-control" [(ngModel)]="nuevoPrecioEspecial.fechaInicio">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                <label class="col-lg-4 col-form-label">Fecha fin:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="date" class="form-control" [(ngModel)]="nuevoPrecioEspecial.fechaFin" min="{{nuevoPrecioEspecial.fechaInicio}}">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Precio adulto:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="number" class="form-control" [(ngModel)]="nuevoPrecioEspecial.precioAdulto" maxlength="5">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Precio niño:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="number" class="form-control" [(ngModel)]="nuevoPrecioEspecial.precioNinio" maxlength="5">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Aforo:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="number" class="form-control" [(ngModel)]="nuevoPrecioEspecial.aforo" maxlength="5">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Concepto:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="text" class="form-control" [(ngModel)]="nuevoPrecioEspecial.concepto" maxlength="200">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Sesión <span class="text-danger">*</span></label>
                  <div class="col-lg-4">
                      <input type="time" class="form-control" [(ngModel)]="nuevoPrecioEspecial.sesion" maxlength="5">
                  </div>
                  <button type="button" class="btn btn-primary col-lg-3" id="btn-add-sesion-nuevo-dia" (click)="addSesionNuevoDia()">Añadir sesión</button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-12 col-form-label">¿Mantener cierre general de días?<span class="text-danger">*</span>
                    <input type="checkbox" [(ngModel)]="nuevoPrecioEspecial.cierre_dias" style="margin-left: 5px;">
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive table-grid">
              <table class="table table-striped table-bordered table-vcenter table-hover">
                  <thead>
                      <tr>
                        <th style="width:50px"></th>
                        <th>Sesión</th>
                        <th style="width:50px">Borrar</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of nuevoPrecioEspecial.sesionesArray" class="cursor">
                      <td><i class="icofont-clock-time"></i></td>
                      <td class="font-bold text-primary">
                          <div [innerHTML]="item"></div>
                      </td>
                      <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelSesionModalNuevoPrecioEspecial(item)"><i class="fa fa-trash text-white"></i></a></td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-nuevo-dia-especial" (click)="GuardarNuevoDiaEspecial()">Guardar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal fade" id="modal-editar-dia-especial" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content modal-lg">
      <div class="modal-header">
          <button type="button" class="close"  (click)="CancelEditarDiaEspecial()"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
          <h4 class="modal-title font-bold"></h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Fecha inicio:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="date" class="form-control" [(ngModel)]="editarPrecioEspecial.fecha_inicio">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                <label class="col-lg-4 col-form-label">Fecha fin:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="date" class="form-control" [(ngModel)]="editarPrecioEspecial.fecha_fin" min="{{editarPrecioEspecial.fecha_inicio}}">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Precio adulto:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="number" class="form-control" [(ngModel)]="editarPrecioEspecial.precio_adultos" maxlength="5">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Precio niño:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="number" class="form-control" [(ngModel)]="editarPrecioEspecial.precio_ninios" maxlength="5">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Aforo:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="number" class="form-control" [(ngModel)]="editarPrecioEspecial.aforo" maxlength="5">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Concepto:<span class="text-danger">*</span></label>
                  <div class="col-lg-8">
                      <input type="text" class="form-control" [(ngModel)]="editarPrecioEspecial.concepto" maxlength="200">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-4 col-form-label">Sesión <span class="text-danger">*</span></label>
                  <div class="col-lg-4">
                      <input type="time" class="form-control" [(ngModel)]="editarPrecioEspecial.sesion" maxlength="5">
                  </div>
                  <button type="button" class="btn btn-primary col-lg-3" id="btn-add-sesion-editar-dia" (click)="addSesionEditarDia()">Añadir sesión</button>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-lg-12 col-form-label">¿Mantener cierre general de días?<span class="text-danger">*</span>
                    <input type="checkbox" [(ngModel)]="editarPrecioEspecial.cierre_dias" style="margin-left: 5px;">
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive table-grid">
              <table class="table table-striped table-bordered table-vcenter table-hover">
                  <thead>
                      <tr>
                        <th style="width:50px"></th>
                        <th>Sesión</th>
                        <th style="width:50px">Borrar</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of editarPrecioEspecial.sesionesArray" class="cursor">
                      <td><i class="icofont-clock-time"></i></td>
                      <td class="font-bold text-primary">
                          <div [innerHTML]="item"></div>
                      </td>
                      <td class="text-center btntd"><a class="btn btn-danger btn-xs" (click)="DelSesionModalEditarPrecioEspecial(item)"><i class="fa fa-trash text-white"></i></a></td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
          <button type="button" class="btn btn-white" (click)="CancelEditarDiaEspecial()">Cancelar</button>
          <button type="button" class="btn btn-primary" id="btn-guardar-nuevo-dia-especial" (click)="GuardarEditarDiaEspecial()">Guardar</button>
      </div>
    </div>
  </div>
</div>
<div class="modal inmodal fade" id="modal-mapa" tabindex="-1" role="dialog"  aria-hidden="true">
  <div class="modal-dialog modal-lg">
      <div class="modal-content">
          <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" style="font-size: 3rem;"><span aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
              <h4 class="modal-title">Seleccione localizaci&oacute;n</h4>
              <small></small>
          </div>
          <div id="mapa-container" class="modal-body" style="padding: 0;">
              <div id="mapa" style="width:100%;height:400px;"></div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-white" style="margin-right: auto;" data-dismiss="modal">Cancelar</button>
              <button type="button" class="btn btn-primary" (click)="SelecLocalizacion()">Seleccionar</button>
          </div>
      </div>
  </div>
</div>