import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation} from '@angular/core';
import { Meta, Title} from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let ICAL: any;
declare let Litepicker: any;
declare let RealexHpp: any;

@Component({
  selector: 'app-pago-anticipo',
  templateUrl: './pago-anticipo.component.html',
  styleUrls: ['./pago-anticipo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PagoAnticipoComponent implements OnInit {
  public id_usuario:string = (this.globals.GetQueryString('cliente') ? this.globals.GetQueryString('cliente') : '');
  public data:any = {};
  public token:string = '';
  public formas_pago:any = [];
  public prefijoPago:string = '';
  public porcentanticipo:number = 100;
  public totalpago:any = null;
  public expirado:boolean = false;

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Petición de pago');
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.2)',
      imageResizeFactor: 0.5,
      text: 'Cargando datos ...'
    });
    //Token
    this.token = this.globals.GetQueryString('token');
    this.prefijoPago = this.globals.RandomStr(6,'1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');
    // console.log(this.prefijoPago);
    this.globals.Api('/pub-config-anticipo').subscribe(data => {
      if (!data || data.error) {
        if (this.token && this.token != '') {
          this.CargarDatos();
        }
        return;
      }
      this.porcentanticipo = data.porcentaje_anticipo;
      if (this.token && this.token != '') {
        this.CargarDatos();
      }
    }, error => {
      if (this.token && this.token != '') {
        this.CargarDatos();
      }
    });
  }
  CargarDatos() {
    this.globals.Api('/pub-get-reserva', {anticipo_url_id: this.token}).subscribe(data => {
      $.LoadingOverlay("hide", true);
      if (!data) return;
      this.data = data;
      this.data.total_con_porcentanticipo = this.data.total * this.porcentanticipo / 100;
      if (this.data.anticipo_fecha_expiracion) {
        if (moment().unix() > moment(this.data.anticipo_fecha_expiracion).unix()) this.expirado = true;
        this.data.anticipo_fecha_expiracion_format = moment(this.data.anticipo_fecha_expiracion).format('DD/MM/YYYY')+' a las '+
        moment(this.data.anticipo_fecha_expiracion).format('HH:mm')+'h';
      }
      if (this.data.anticipo_importe && this.data.anticipo_importe != '') {
        this.totalpago = this.data.anticipo_importe;
      } else {
        this.totalpago = this.data.total;
      }
      this.appref.tick();
      this.CargarFormasPago();
    }, error => {
      $.LoadingOverlay("hide", true);
    });
  }
  CargarFormasPago() {
    this.globals.Api('/pub-formas-pago-anticipo', {id_usuario: this.id_usuario, idioma: 'es'}).subscribe(data => {
      // console.table(data);
      data = JSON.parse(JSON.stringify(data));
      if (!data || !data.length) return;
      this.formas_pago = data;
      this.formas_pago.splice(this.formas_pago.findIndex(el => el.slug == 'pago-mano'), 1);
      this.formas_pago.splice(this.formas_pago.findIndex(el => el.slug == 'transferencia'), 1);
    });
  }
  SetFormaPago(item:any) {
    if (!item) return;
    for (let al of this.formas_pago) al.checked = false;
    item.checked = true;
    this.data.cli_tipo_pago = item.forma_pago;
    this.data.cli_tipo_pago_slug = item.slug;

    this.RealizarPago();
  }
  RealizarPago() {
    $.LoadingOverlay("show", {
      background: 'rgba(255, 255, 255, 0.6)',
      imageResizeFactor: 0.5,
      text: 'Espere ...'
    });
    let totalPago = 0;
    if (this.data.anticipo_importe && this.data.anticipo_importe != '') {
      totalPago = this.data.anticipo_importe;
    } else {
      totalPago = this.data.total;
    }
    this.globals.Api('/pub-get-pago-reserva', {
      anticipo_url_id: this.token,
      total: totalPago,
      cli_tipo_pago_slug: this.data.cli_tipo_pago_slug,
      pago_anticipo: true,
      prefijo_pago: this.prefijoPago
    }).subscribe(data => {
      if (!data || !data.value) {
        $.LoadingOverlay("hide", true);
        toastr.error('Lo sentimos, se ha producido un error');
        return;
      }
      if (this.data.cli_tipo_pago_slug == 'addon-payments') {
        this.OpenAddonPayments();
        return;
      }
      if (data.form && data.form != '') {
        $('body').append(data.form);
        setTimeout(() => {
          if ($('#paypal-form').length) {
            $('#paypal-form').submit();
            $('#paypal-form').remove();
          }
          if ($('#redsys-form').length) {
            $('#redsys-form').submit();
            $('#redsys-form').remove();
          }
        }, 100);
        return;
      }
      $.LoadingOverlay("hide", true);
      toastr.error('Lo sentimos, se ha producido un error');
    }, error => {
      $.LoadingOverlay("hide", true);
      toastr.error('Lo sentimos, se ha producido un error');
    });
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.parent.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
        window.postMessage({
          url: window.location.href,
          height: $(window).height()
        }, '*');
      }
    });
    window.parent.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
    window.postMessage({
      url: window.location.href,
      height: $(window).height()
    }, '*');
  }
  OpenAddonPayments() {
    if (this.data.cli_tipo_pago_slug == 'addon-payments') {
      $.LoadingOverlay("show", {
        background: 'rgba(255, 255, 255, 0.6)',
        imageResizeFactor: 0.5,
        text: 'Espere ...'
      });
      setTimeout(() => {
        this.data.id_usuario = this.id_usuario;
        this.data.total = this.data.anticipo_importe;
        this.globals.Api('/pub-addon-payments-form', this.data).subscribe((data:any) => {
          if (!data) {
            $.LoadingOverlay("hide", true);
            toastr.error('Lo sentimos, se ha producido un error');
            return;
          }
          // console.log(data.HPP_POST_RESPONSE);
          RealexHpp.setHppUrl(data.PAY_URL);
          setTimeout(() => {
            $.LoadingOverlay("hide", true);
            $('.blackdrop').show();
            RealexHpp.embedded.init("autoload", "iframe-addon-payments",
              (answer, close) => {
                // console.log('embed answer', answer);
                close();
                if (answer.AUTHCODE) {
                  $.LoadingOverlay("show", {
                    background: 'rgba(255, 255, 255, 0.6)',
                    imageResizeFactor: 0.5,
                    text: 'Espere ...'
                  });
                  this.globals.Api('/pub-addon-payments-ipn', {orderid: data.ORDER_ID}).subscribe(data => {
                    // console.log(data);
                    if (!data || !data.value) {
                      $.LoadingOverlay("hide", true);
                      toastr.error('Lo sentimos, se ha producido un error');
                      return;
                    }
                    swal('¡Reserva realizada con éxito!', 'Recibirá un correo a la dirección facilitada con la confirmación de la reserva.', 'success');
                    this.ngZone.run(() => this.router.navigateByUrl('/confirmacion-reserva?cliente='+this.id_usuario+'&token='+this.data.token_reserva)).then();
                  });
                } else {
                  toastr.error('Lo sentimos, se ha producido un error');
                  $('.blackdrop').hide();
                  // console.log(answer.MESSAGE);
                  this.globals.Api('/pub-addon-payments-fallida', {id: this.data.id}).subscribe();
                  $('.iframe-container').html(`<iframe id="iframe-addon-payments" frameborder="0" style="display:none"></iframe>`);
                }
              }, data, { onResize: function(data) {
                $('#iframe-addon-payments').css(data)
              }});
            window.addEventListener('message', response => {
              // console.log(response.data);
            }, false);
          }, 100);
          $('body').addClass('loaded');
        });
      }, 200);
    }
  }

}
