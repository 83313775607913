<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>
  
            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->
  
        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->
  
            <!-- Page content -->
            <div id="page-content" class="add-edit-actividad-page">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li><a [routerLink]="['/bonos-regalo']">Bonos regalo</a></li>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Código <span class="text-danger">*</span></label>
                          <label class="font-normal font-12 font-italic m-l-10">Dejar vacío para autogenerar al introducir los emails</label>
                          <input type="text" class="form-control" [(ngModel)]="data.codigo" maxlength="50" placeholder="Dejar vacío para autogenerar" (change)="GenerarCodigo()">
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Estado</label>
                          <select class="form-control" [(ngModel)]="data.id_estado_bono">
                              <option [value]="item.id" *ngFor="let item of estados">{{item.estado}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-md-4 col-sm-12">
                        <div class="form-group">
                          <label>Válido hasta</label>
                          <input type="date" class="form-control" [(ngModel)]="data.validez" maxlength="10">
                        </div>
                    </div>
                </div>
                <hr style="margin: 10px 0;">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="block">
                            <div class="block-title"><h2>Actividad</h2></div>
                            <select class="form-control m-b-20" [(ngModel)]="data.id_actividad" (change)="CalcularTotal()">
                                <option [value]="item.id" *ngFor="let item of actividades">{{item.nombre}}</option>
                            </select>
                        </div>
                        <div class="block" *ngIf="data.reserva && data.reserva.nombre">
                            <div class="block-title"><h2>Reserva asociada</h2></div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="block">
                            <div class="block-title"><h2>Resumen</h2></div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Adultos <span class="text-danger">*</span></label>
                                        <input type="number" class="form-control" [(ngModel)]="data.adultos" maxlength="10" step="1" min="0" (change)="CalcularTotal()">
                                    </div>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <div class="form-group">
                                        <label>Niños</label>
                                        <input type="number" class="form-control" [(ngModel)]="data.ninios" maxlength="10" step="1" min="0" (change)="CalcularTotal()">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="font-weight-normal inline-block">TOTAL</label>
                                <label class="inline-block m-l-10 text-primary font-18">{{globals.FloatES(data.total)}} €</label>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin: 10px 0;">
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="block">
                            <div class="block-title"><h2>Persona que regala (Compra)</h2></div>
                            <div class="row m-t-10">
                                <div class="col-md-5 col-xs-12">
                                    <div class="form-group">
                                        <label>Nombre <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" [(ngModel)]="data.nombre_regalo" maxlength="80">
                                    </div>
                                </div>
                                <div class="col-md-7 col-xs-12">
                                    <div class="form-group">
                                        <label>Apellidos <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" [(ngModel)]="data.apellidos_regalo" maxlength="80">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                        <label>C&oacute;digo postal</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.codigo_postal_regalo" maxlength="10"
                                        (change)="DatosLocalidad('comprador')">
                                    </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                        <label>Localidad</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.localidad_regalo" maxlength="50">
                                    </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                        <label>Provincia</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.provincia_regalo" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Tel&eacute;fono</label>
                                        <input type="tel" class="form-control" [(ngModel)]="data.telefono_regalo" maxlength="20">
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Email <span class="text-danger">*</span></label>
                                        <input type="email" class="form-control" [(ngModel)]="data.email_regalo" maxlength="100" (change)="GenerarCodigo()">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="block">
                            <div class="block-title"><h2>Persona que recibe</h2></div>
                            <div class="row m-t-10">
                                <div class="col-md-5 col-xs-12">
                                    <div class="form-group">
                                        <label>Nombre <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                    </div>
                                </div>
                                <div class="col-md-7 col-xs-12">
                                    <div class="form-group">
                                        <label>Apellidos <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                        <label>C&oacute;digo postal</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.codigo_postal" maxlength="10"
                                        (change)="DatosLocalidad('recibe')">
                                    </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                        <label>Localidad</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="50">
                                    </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                        <label>Provincia</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="50">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Tel&eacute;fono</label>
                                        <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                                    </div>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <div class="form-group">
                                        <label>Email <span class="text-danger">*</span></label>
                                        <input type="email" class="form-control" [(ngModel)]="data.email" maxlength="100" (change)="GenerarCodigo()">
                                    </div>
                                </div>
                            </div>
                            <hr style="margin: 10px 0;">
                            <div class="row">
                                <div class="col-md-12 col-xs-12">
                                    <div class="form-group">
                                        <label>Dedicatoria</label>
                                        <textarea class="form-control" [(ngModel)]="data.dedicatoria" maxlength="500" rows="4"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>
  
            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
  </div>