import { ApplicationRef, Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;

declare let LANG: any;
declare let L: any;

@Component({
  selector: 'app-reserva-error',
  templateUrl: './reserva-error.component.html',
  styleUrls: ['./reserva-error.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReservaErrorComponent implements OnInit {
  public L:any = L;
  public data:any = {};
  public redsysparameters:string = '';
  public redsysorderid:string = '';
  public orderid:string = '';
  public token:string = '';

  constructor(
    public title: Title,
    public meta: Meta,
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Error en la reserva');
    // $.LoadingOverlay("show", {
    //   background: 'rgba(255, 255, 255, 0.2)',
    //   imageResizeFactor: 0.5,
    //   text: 'Cargando datos ...'
    // });

    LANG = this.globals.GetQueryString('lang');
    if (!LANG || LANG == '') LANG = 'es';

    //Redsys
    this.redsysparameters = this.globals.GetQueryString('Ds_MerchantParameters');
    if (this.redsysparameters && this.redsysparameters != '') {
      this.data.isredsys = true;
      let params = JSON.parse(atob(this.redsysparameters));
      this.data.redsys_order = params.Ds_Order;
      this.globals.Api('/pub-redsys-ko', {ds_merchant_parameters: this.globals.GetQueryString('Ds_MerchantParameters')}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          return;
        }
        this.CargarDatos();
      });
    }
    this.redsysorderid = this.globals.GetQueryString('redsysorderid');
    if (this.redsysorderid && this.redsysorderid != '') {
      this.data.isredsys = true;
      this.globals.Api('/pub-redsys-ko-orderid', {orderid: this.redsysorderid}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?redsysorderid='+this.redsysorderid)).then();
          return;
        }
        this.CargarDatos();
      }, error => {
        $.LoadingOverlay("hide", true);
        this.ngZone.run(() => this.router.navigateByUrl('/reserva-error?redsysorderid='+this.redsysorderid)).then();
      });
    }
    //Paypal
    this.orderid = this.globals.GetQueryString('orderid');
    if (this.orderid && this.orderid != '') {
      this.data.paypal_order = this.orderid;
      this.globals.Api('/pub-paypal-ko', {orderid: this.orderid}).subscribe(data => {
        if (!data || data.error) {
          $.LoadingOverlay("hide", true);
          return;
        }
        this.CargarDatos();
      });
    }
    //Token
    this.token = this.globals.GetQueryString('token');
    if (this.token && this.token != '') {
      this.CargarDatos();
    }
  }
  CargarDatos() {
    if (this.redsysparameters && this.redsysparameters != '') {
      this.globals.Api('/pub-get-reserva', {redsys_order: this.data.redsys_order}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = true;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    } 
    if (this.redsysorderid && this.redsysorderid != '') {
      this.globals.Api('/pub-get-reserva', {redsys_order: this.redsysorderid}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
    if (this.orderid && this.orderid != '') {
      this.globals.Api('/pub-get-reserva', {paypal_order: this.data.paypal_order}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
    if (this.token && this.token != '') {
      this.globals.Api('/pub-get-reserva', {reserva_token: this.token}).subscribe(data => {
        $.LoadingOverlay("hide", true);
        if (!data) return;
        this.data = data;
        this.data.isredsys = false;
        this.appref.tick();
      }, error => {
        $.LoadingOverlay("hide", true);
      });
    }
  }

}
