import { ApplicationRef, Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-edit-bono-regalo',
  templateUrl: './edit-bono-regalo.component.html'
})
export class EditBonoRegaloComponent implements OnInit {
  public title:string = 'Nuevo bono regalo';
  public data:any = {adultos: 0, ninios: 0, total: 0};
  public estados:any = [];
  public actividades:any = [];

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    if (window.location.pathname.indexOf('add') != -1) this.globals.passData = null;
    if (window.location.pathname.indexOf('edit') != -1 && !Object.keys(this.globals.passData).length) window.location.href = window.location.href.replace('edit', 'add');
    if (this.globals.passData && this.globals.passData != '' && Object.keys(this.globals.passData).length > 0) this.data = this.globals.passData;
    if (!this.data.id || this.data.id == '') {
      this.title = 'Nuevo bono regalo';
      this.data.validez = moment(this.data.validez).add(6, 'months').format('YYYY-MM-DD');
    } else {
      this.title = 'Bono ' + this.data.codigo;
      if (this.data.validez) this.data.validez = moment(this.data.validez).format('YYYY-MM-DD');
    }
    this.globals.Api('/eve-estado-bono-regalo').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.estados = data;
      if (!this.data.id_estado_bono && this.estados.length) this.data.id_estado_bono = 2;
    });
    this.globals.Api('/eve-eventos').subscribe((data:any) => {
      if (!data || !data.length) return;
      this.actividades = data;
      if (!this.data.id_actividad && this.actividades.length) this.data.id_actividad = this.actividades[0].id;
      if (!this.data.id || this.data.id == '') this.CalcularTotal();
    });
  }
  
  DatosLocalidad(tipo:string = 'recibe') {
    let codigo_postal = this.data.codigo_postal;
    if (tipo == 'comprador') codigo_postal = this.data.codigo_postal_regalo;
    this.globals.Api('/localidad', {codpostal: codigo_postal}).subscribe(data => {
      if (!data) {
        if (tipo == 'recibe') {
          this.data.localidad = '';
          this.data.provincia = '';
        } else {
          this.data.localidad_regalo = '';
          this.data.provincia_regalo = '';
        }
        this.appref.tick();
        return;
      }
      if (tipo == 'recibe') {
        this.data.localidad = data.localidad;
        this.data.provincia = data.provincia;
      } else {
        this.data.localidad_regalo = data.localidad;
        this.data.provincia_regalo = data.provincia;
      }
      this.appref.tick();
    });
  }
  CalcularTotal() {
    let actividad:any = this.actividades.find((el:any) => el.id == this.data.id_actividad);
    if (!actividad) return;
    let totaladultos:number = this.data.adultos * actividad.precio;
    let totalninios:number = this.data.ninios * actividad.precio_ninios;
    if (isNaN(totaladultos)) totaladultos = 0;
    if (isNaN(totalninios)) totalninios = 0;
    this.data.total = totaladultos + totalninios;
  }
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }
  GenerarCodigo() {
    if (this.data.id && this.data.id != '') return;
    if (this.data.codigo && this.data.codigo != '') return;
    this.data.codigo = 'A'+this.data.email.substring(0,2).toUpperCase()+''+this.getRandomInt(10000,99999)+''+this.data.email_regalo.substring(0,2).toUpperCase();
  }

  Cancelar() {
    this.ngZone.run(() => this.router.navigateByUrl('/bonos-regalo')).then();
  }
  Guardar() {
    if (
      !this.data.codigo
      || !this.data.nombre
      || !this.data.nombre_regalo
      || !this.data.email
      || !this.data.email_regalo
      || !this.data.adultos
      || this.data.codigo == ''
      || this.data.nombre == ''
      || this.data.nombre_regalo == ''
      || this.data.email == ''
      || this.data.email_regalo == ''
      || this.data.adultos == ''
      ) {
      swal('Complete campos', 'Por favor, rellene todos los campos obligatorios para continuar', 'error');return;
    }

    this.title = 'Bono '+this.data.codigo;
    if (!this.data.id || this.data.id == '') {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-bonos-regalo-add', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.Cancelar();
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    } else {
      $.buttonloading('.btn-guardar', 'Enviando');
      this.globals.Api('/eve-bonos-regalo-edit', this.data).subscribe(data => {
        $.buttoncancelloading('.btn-guardar');
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        toastr.success(`Datos guardados correctamente`, 'Guardado');
      }, error => {
        $.buttoncancelloading('.btn-guardar');
      });
    }
  }

}
