import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;
declare let tinymce: any;

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfiguracionComponent implements OnInit {
  public temporadas = [];
  public data:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    tinymce.remove();
    if (this.globals.GetData()) this.data = this.globals.GetData();
    this.globals.Api('/configuracion').subscribe(data => {
      if (!data) return;
      this.data = data;
      this.globals.SaveData(data);
    });
    setTimeout(() => {
      let move = function (arr, from, to) {
        arr.splice(to, 0, arr.splice(from, 1)[0]);
      };
      let that = this;
      tinymce.init({
        selector:'.text-editor',
        height: 300,
        plugins: 'print preview powerpaste casechange importcss tinydrive searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions quickbars linkchecker emoticons advtable',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        language: 'es'
      });
    }, 100);
  }

  Guardar() {
    this.data.politicas_cancelacion = tinymce.EditorManager.get('txtcancelacion').getContent();
    $.buttonloading('.btn-guardar', 'Enviando');
    this.globals.Api('/configuracion-edit', this.data).subscribe(data => {
      $.buttoncancelloading('.btn-guardar');
      if (!data || data.error) {
        swal('Error', 'Se ha producido un error', 'error');return;
      }
      swal('Guardado', 'Datos guardados correctamente', 'success');
    }, error => {
      $.buttoncancelloading('.btn-guardar');
    });
  }

}
