<div class="response-reserva" *ngIf="!data.id || (data.id && [1,2,7].includes(data.id_estado) && !expirado)">
  <h1 style="font-weight:bold;">Petición de pago</h1>
  <p class="font-18" style="line-height: 1.2em;">
    Para confirmar tu reserva es necesario el pago de un anticipo de
    <ng-container *ngIf="porcentanticipo != 100 && data.total_con_porcentanticipo">
      <b class="font-20">{{globals.FloatES(data.total_con_porcentanticipo)}}€</b>.
      <br><br>
      <div class="font-18">Importe de la reserva: {{globals.FloatES(data.total)}}€</div>
      <div class="font-18">Anticipo: {{globals.FloatES(porcentanticipo)}}%</div>
      <div class="font-18 font-bold">Total: {{globals.FloatES(data.total_con_porcentanticipo)}}€</div>
    </ng-container>
    <ng-container *ngIf="porcentanticipo == 100">
      <ng-container *ngIf="totalpago">
        <b class="font-20">{{globals.FloatES(totalpago)}}€</b>.
      </ng-container>
    </ng-container>
    <br>Selecciona una forma de pago:
  </p>

  <div class="row row-small datos-reserva" style="margin-bottom:10px !important;">
      <div class="col-xs-12">

          <ul class="list-group">
              <li class="list-group-item active">Formas de pago</li>
              <li class="list-group-item">
                  <a class="btn btn-default btn-{{item.slug}} m-r-10" (click)="SetFormaPago(item)" *ngFor="let item of formas_pago">
                      <label class="csscheckbox csscheckbox-warning"><input type="checkbox" [checked]="item.checked"><span></span></label>
                      <span class="texto" [innerHTML]="item.forma_pago"></span>
                  </a>
              </li>
              <li class="list-group-item" *ngIf="data.anticipo_fecha_expiracion_format">
                  Dispone hasta el <strong>{{data.anticipo_fecha_expiracion_format}}</strong><span *ngIf="data.anticipo_hora_expiracion"> a las <strong>{{data.anticipo_hora_expiracion}}h</strong></span>
                  para realizar el pago
              </li>
          </ul>
      </div>
  </div>

  <p>A continuación te mostramos los detalles de tu reserva:</p>
  <div class="row row-small datos-reserva" *ngIf="data && data.id">
    <div class="col-xs-12 col-sm-6">
      <ul class="list-group">
          <li class="list-group-item active">Tus datos</li>
          <li class="list-group-item datos">
            <div class="m-b-5">{{data.cli_nombre}} {{data.cli_apellidos}}</div>
            <div *ngIf="data.cli_empresa">{{data.cli_empresa}}<span *ngIf="data.cli_nifcif">, {{data.cli_nifcif}}</span></div>
            <div>{{data.cli_direccion}}<span *ngIf="data.cli_codpostal">, {{data.cli_codpostal}}</span></div>
            <div>{{data.cli_localidad}}<span *ngIf="data.cli_pais">, {{data.cli_pais}}</span></div>
            <div style="word-break: break-all;" *ngIf="data.cli_email"><i class="fa fa-envelope-o"></i> {{data.cli_email}}</div>
            <div *ngIf="data.cli_telefono"><i class="fa fa-phone"></i> {{data.cli_telefono}}</div>
            <div *ngIf="data.cli_notas"><br>{{data.cli_notas}}</div>
          </li>
      </ul>
    </div>
    <div class="col-xs-12 col-sm-6">
      <ul class="list-group">
          <li class="list-group-item active">Datos de la reserva</li>
          <li class="list-group-item datos">
            <div class="dato-destacado m-b-5">#{{data.referencia}}</div>
            <div class="dato-destacado m-b-5">{{data.evento}}</div>
            <div>Fecha: {{data.fecha}}</div>
            <div>Sesión: {{data.sesion}}</div>
            <div>Adultos: {{data.adultos}}</div>
            <div>Niños<span *ngIf="data.ninos_rango_edad" class="text-muted font-11"> {{data.ninos_rango_edad}}</span>: {{data.ninos}}</div>
            <div>Precio: {{globals.FloatES(data.total)}}€</div>
          </li>
          <ng-container *ngIf="porcentanticipo != 100 && data.total_con_porcentanticipo">
            <li class="list-group-item">Importe de la reserva: {{globals.FloatES(data.total)}}€</li>
            <li class="list-group-item">Anticipo: {{globals.FloatES(porcentanticipo)}}%</li>
            <li class="list-group-item active font-bold font-20">Total: {{globals.FloatES(data.total_con_porcentanticipo)}}€</li>
          </ng-container>
          <ng-container *ngIf="porcentanticipo == 100">
            <li class="list-group-item active font-bold font-20">
              Total
              <div class="pull-right">{{globals.FloatES(data.total)}}€</div>
              <div class="clearfix"></div>
            </li>
          </ng-container>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
</div>
<div class="response-reserva" *ngIf="data.id && ![1,2,7].includes(data.id_estado) && !expirado">
  <h1 style="font-weight:bold;font-size:24px;">Solicita una nueva petición de pago</h1>
  <br>
  <p class="font-18" style="line-height: 1.2em;" *ngIf="globals.config && globals.config.nombre">
    Por favor, contacta con <b class="text-primary">{{globals.config.nombre}}</b> para solicitar una nueva petición de pago.
    <br><br>
    ¡Gracias!
  </p>
</div>
<div class="response-reserva" *ngIf="data.id && expirado">
  <h1 style="font-weight:bold;font-size:24px;">La fecha para hacer el pago ha expirado</h1>
  <br>
  <p class="font-18" style="line-height: 1.2em;" *ngIf="globals.config && globals.config.nombre">
    Por favor, contacta con <b class="text-primary">{{globals.config.nombre}}</b> para solicitar una nueva petición de pago.
    <br><br>
    ¡Gracias!
  </p>
</div>