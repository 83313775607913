<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">{{title}}</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <ng-container *ngIf="!cliente && !colaborador && !guias">
                                  <li><a [routerLink]="['/usuarios']">Usuarios</a></li>
                                </ng-container>
                                <ng-container *ngIf="cliente">
                                  <li><a [routerLink]="['/clientes']">Clientes</a></li>
                                </ng-container>
                                <ng-container *ngIf="colaborador">
                                  <li><a [routerLink]="['/colaboradores']">Colaboradores</a></li>
                                </ng-container>
                                <ng-container *ngIf="guias">
                                  <li><a [routerLink]="['/guias']">Guías</a></li>
                                </ng-container>
                                <li>{{title}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <li class="active" *ngIf="data.id_rol == 4 && title != 'Nuevo colaborador'"><a href="#tab-reservas">Reservas colaborador</a></li>
                            <li *ngIf="data.id_rol == 4 && title != 'Nuevo colaborador'"><a href="#tab-general">Datos generales</a></li>
                            <li class="active" *ngIf="data.id_rol != 4 || title == 'Nuevo colaborador'"><a href="#tab-general">Datos generales</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-reservas" *ngIf="data.id_rol == 4 && title != 'Nuevo colaborador'">
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <div class="row">
                                <h3 style="font-weight: 700;">Reservas de {{title}}</h3>
                                <div class="clo-lg-12">
                                  <a [routerLink]="['/reservas']" class="widget custom-widget">
                                    <span class="title-counter sen-extrabold counter-total-reservas">Reservas para hoy</span>
                                    <hr class="separador-general" />
                                    <h2 class="counter-dashboard counter-min" *ngIf="reservas">
                                        <strong>
                                            <span data-toggle="counter" [attr.data-to]="reservas.total_reservas_hoy">{{reservas.total_reservas_hoy}}</span>
                                            <span class="reservas-hoy-personas" data-toggle="counter" [attr.data-to]="reservas.total_reservas_hoy">({{reservas.total_reservas_personas_hoy}} personas)</span>
                                        </strong><br/>
                                        <span class="subtitle-counter sen-extrabold ">Totales ({{reservas.total_reservas}})</span>
                                    </h2>
                                    <div class="widget-content widget-content-mini text-left clearfix no-padding-right">
                                        <span class="text-new-mas reservas">Ver todas<br/>las reserva</span>
                                        <div class="widget-icon pull-right no-background">
                                            <img src="assets/icon-eye.svg" />
                                        </div>
                                    </div>
                                  </a>
                                </div>
                                <div class="clo-lg-12">
                                  <div class="widget custom-widget">
                                    <div class="row">
                                        <div class="col-sm-4 col-lg-3">
                                            <span id="title-grafica" class="title-counter sen-extrabold  sen-extrabold">Reservas <br/>acumuladas</span>
                                            <hr class="separador-general" />
                                        </div>
                                        <div class="col-sm-8 col-lg-9 margin-top-15-movil">
                                            <div class="row">
                                                <div class="col-sm-3 col-lg-3 col-xs-4 reservas">
                                                    <h2 class="counter-dashboard" *ngIf="data">
                                                        <strong style="line-height: 0px;"><span data-toggle="counter" [attr.data-to]="reservas.total_reservas_semana">{{reservas.total_reservas_semana}}</span><br/><span style="font-size: 12px;">({{reservas.total_reservas_personas_semana}} Personas)</span></strong><br/>
                                                        <span class="subtitle-counter subtitle-red">Semana</span>
                                                    </h2>
                                                </div>
                                                <div class="col-sm-3 col-lg-3 col-xs-4 center separador-grafica-left-right">
                                                    <h2 class="counter-dashboard" *ngIf="reservas">
                                                        <strong style="line-height: 0px;"><span data-toggle="counter" [attr.data-to]="reservas.total_reservas_mes">{{reservas.total_reservas_mes}}</span><br/><span style="font-size: 12px;">({{reservas.total_reservas_personas_mes}} Personas)</span></strong><br/>
                                                        <span class="subtitle-counter subtitle-red">Mes</span>
                                                    </h2>
                                                </div>
                                                <div class="col-sm-3 col-lg-3 col-xs-4 center">
                                                    <h2 class="counter-dashboard" *ngIf="reservas">
                                                        <strong style="line-height: 0px;"><span data-toggle="counter" [attr.data-to]="reservas.total_reservas">{{reservas.total_reservas_anio}}</span><br/><span style="font-size: 12px;">({{reservas.total_reservas_personas_anio}} Personas)</span></strong><br/>
                                                        <span class="subtitle-counter subtitle-red">Año</span>
                                                    </h2>
                                                </div>
                                            </div>
                                            <hr class="separador-grafica" />
                                            <br/>
                                        </div>
                                    </div>
                                    <div id="content-grafica" height="100px">
                                        <canvas id="myChart"></canvas>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                              <h3 style="font-weight: 700;">Mostrando las del mes actual</h3>
                              <div class="row margin-bottom-10">
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                  <select id="select-mes" class="custom-select" (change)="FiltrarMesAnio()">
                                    <option default>Selecciona un mes</option>
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                  </select>
                                </div>
                                <div class="col-lg-3 col-md-3 col-sm-3">
                                  <select id="select-anio" class="custom-select" (change)="FiltrarMesAnio()">
                                    <option default>Selecciona un año</option>
                                    <option value="{{anio}}" *ngFor="let anio of anios">{{anio}}</option>
                                  </select>
                                </div>
                                <div class="col-lg-2 col-md-2 col-sm-2">
                                  <button type="button" class="btn btn-primary btn-filtrar" (click)="FiltrarMesAnio()"><i class="fa fa-filter" aria-hidden="true"></i> Filtrar</button>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-4">
                                  <button type="button" class="btn btn-primary btn-excel" (click)="ExportarExcel()"><i class="fa fa-file-excel-o"></i> Exportar a Excel</button>
                                </div>
                              </div>
                              <div class="row row-flexbox">
                                <ng-container *ngFor="let item of reservasTarjetas">
                                    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="padding: 0 10px;">
                                        <a href="javascript:void(0)" class="
                                          widget 
                                          reserva-restaurante 
                                          {{item.tipo_slug}} {{item.id_estado == 4 || item.id_estado == 5  || item.id_estado == 6  ? 'bg-tr-cancelada' : null}}
                                          " (click)="Editar(item)">
                                            <div class="widget-content text-right clearfix">
                                                <div class="pull-left icon-tipo text-left" style="width:38%;">
                                                    <!-- <i class="fa fa-ticket sidebar-nav-icon img-circle img-thumbnail img-thumbnail-avatar"
                                                        style="font-size:35px;text-align:center;color:var(--color-primary);line-height: 1.6;"></i> -->
                                                    <span class="badge-reserva">
                                                      <img *ngIf="item.tipo == 'Web'" src="assets/icon-ref-web.svg" />
                                                      <img *ngIf="item.tipo == 'Civitatis'" src="assets/icon-ref-c.svg" />
                                                      <img *ngIf="item.tipo == 'Partner'" src="assets/icon-ref-p.svg" />
                                                      <img *ngIf="item.tipo == 'GetYourGuide'" src="assets/icon-ref-g.svg" />
                                                      <img *ngIf="item.tipo == 'Admin'" src="assets/icon-ref-admin.svg" />
                                                      <img *ngIf="item.tipo == 'Airbnb'" src="assets/icon-ref-airbnb.svg" />
                                                      {{item.tipo}}
                                                    </span>
                                                    <h5 class="text-left h5">Fecha: <strong>{{item.fecha_format}}</strong></h5>
                                                    <h5 class="text-left h5">Sesion: <strong>{{item.sesion}}</strong></h5>
                                                    <h5 class="text-left h5" *ngIf="item.cli_tipo_pago">Pago: <strong>{{item.cli_tipo_pago}}</strong></h5>
                                                </div>
                                                <h3 class="widget-heading h4">
                                                  <img src="assets/flag-en.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'en'">
                                                  <img src="assets/flag-it.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'it'">
                                                  <img src="assets/flag-fr.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'fr'">
                                                  <img src="assets/flag-pt.svg" class="idioma" alt="" *ngIf="item.idioma_res == 'pt'">
                                                  <strong class="nombre">{{item.cli_nombre}} {{item.cli_apellidos}}</strong>
                                                </h3>
                                                <div class="h4"><strong>{{item.personas}}</strong> personas</div>
                                                <div class="text-muted breakword" *ngIf="item.cli_email"><i class="fa fa-envelope-o"></i> {{item.cli_email}}</div>
                                                <div class="text-muted" *ngIf="item.cli_telefono"><i class="fa fa-phone"></i> {{item.cli_telefono}}</div>
                                            </div>
                                        </a>
                                    </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane" id="tab-general"  *ngIf="data.id_rol == 4 || title != 'Nuevo colaborador'">
                          <div class="row">
                            <div class="col-lg-4 col-md-12">
                              <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                                <i class="icofont-camera-alt font-50"></i>
                              </div>
                              <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                                <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                                <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                                <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                              </div>
                            </div>
                            <div class="col-lg-8 col-md-12">
                              
                              <div class="row">
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary">Nombre *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label class="text-primary">Apellidos *</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <label class="text-primary">Rol *</label>
                                  <div class="form-group">
                                    <ng-container *ngIf="!cliente && !guias">
                                      <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && globals.me.id_rol == 0">
                                        <option *ngFor="let item of roles" [value]="item.id">{{item.rol}}</option>
                                      </select>
                                      <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && (globals.me.id_rol == 1 || globals.me.id_rol == 2)">
                                        <option *ngFor="let item of roles" [value]="item.id" [disabled]="item.id == 0 || item.id == 1">{{item.rol}}</option>
                                      </select>
                                    </ng-container>
                                    <ng-container *ngIf="cliente">
                                      <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol">
                                        <option value="3" selected>Cliente</option>
                                      </select>
                                    </ng-container>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-8 col-xs-12">
                                  <div class="form-group">
                                    <label>Empresa</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>NIF / CIF</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.nifcif" maxlength="20">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-5 col-xs-12">
                                  <div class="form-group">
                                    <label>Direcci&oacute;n</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                                  </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>C&oacute;digo postal</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                                      (change)="DatosLocalidad()">
                                  </div>
                                </div>
                                <div class="col-md-4 col-xs-12">
                                  <div class="form-group">
                                    <label>Localidad</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Provincia</label>
                                    <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80">
                                  </div>
                                </div>
                                <div class="col-md-3 col-xs-12">
                                  <div class="form-group">
                                    <label>Tel&eacute;fono</label>
                                    <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                                  </div>
                                </div>
                              </div>
                              
                              <div class="block">
                                <div class="block-title"><h2><i class="fa fa-sign-in"></i> Acceso al sistema de reservas</h2></div>
                                <div class="block-content">
                                  <div class="row">
                                    <div class="col-md-5 col-xs-12">
                                      <div class="form-group">
                                        <label class="text-primary"><i class="fa fa-envelope"></i> Email *</label>
                                        <input type="email" class="form-control"[(ngModel)]="data.email" maxlength="80">
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-xs-12">
                                      <div class="form-group" *ngIf="data.id == ''">
                                        <label class="text-primary"><i class="fa fa-lock"></i> Contraseña *</label>
                                        <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                                        <a class="btn btn-block btn-default m-t-10" (click)="GenerarClave()"><i class="fa fa-lock"></i> Generar</a>
                                      </div>
                                      <div class="form-group" *ngIf="data.id != ''">
                                        <a class="btn btn-sm btn-warning text-white m-t-30" (click)="EditarClave()"><i class="fa fa-lock"></i> Cambiar contraseña</a>
                                      </div>
                                    </div>
                                    <div class="col-md-3 col-xs-12">
                                      <div class="form-group">
                                        <label class="text-primary">Acceso</label>
                                        <select class="form-control" [(ngModel)]="data.acceso">
                                          <option value="1">Sí</option>
                                          <option value="0">No</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane active" id="tab-general" *ngIf="data.id_rol != 4 || title == 'Nuevo colaborador'">
                              
                            <div class="row">
                              <div class="col-lg-4 col-md-12">
                                <div *ngIf="!data.imagen || data.imagen == ''" class="cursor text-center m-t-10 no-image" (click)="SelecImagen()">
                                  <i class="icofont-camera-alt font-50"></i>
                                </div>
                                <div class="img-preview-container cursor relative" (click)="SelecImagen()">
                                  <img *ngIf="data.imagen && data.imagen != ''" [src]="data.imagen">
                                  <small class="display-block">(<i class="fa fa-arrows-alt"></i> proporci&oacute;n cuadrada)</small>
                                  <button *ngIf="data.imagen && data.imagen != ''" class="btn btn-danger absolute" (click)="BorrarImagen($event)" style="top:0;right:0"><i class="fa fa-trash"></i></button>
                                </div>
                              </div>
                              <div class="col-lg-8 col-md-12">
                                
                                <div class="row">
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary">Nombre *</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label class="text-primary">Apellidos *</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.apellidos" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <label class="text-primary">Rol *</label>
                                    <div class="form-group">
                                      <ng-container *ngIf="!cliente && !colaborador && !guias">
                                        <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && globals.me.id_rol == 0">
                                          <option *ngFor="let item of roles" [value]="item.id">{{item.rol}}</option>
                                        </select>
                                        <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" *ngIf="globals.me && (globals.me.id_rol == 1 || globals.me.id_rol == 2)">
                                          <option *ngFor="let item of roles" [value]="item.id" [disabled]="item.id == 0 || item.id == 1">{{item.rol}}</option>
                                        </select>
                                      </ng-container>
                                      <ng-container *ngIf="cliente">
                                        <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol">
                                          <option value="3" selected>Cliente</option>
                                        </select>
                                      </ng-container>
                                      <ng-container *ngIf="colaborador">
                                        <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol">
                                          <option value="4" selected>Colaborador</option>
                                        </select>
                                      </ng-container>
                                      <ng-container *ngIf="guias">
                                        <select class="form-control" placeholder="Rol" [(ngModel)]="data.id_rol" disabled="disabled">
                                          <option value="5" selected>Guía</option>
                                        </select>
                                      </ng-container>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-8 col-xs-12">
                                    <div class="form-group">
                                      <label>Empresa</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.empresa" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>NIF / CIF</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.nifcif" maxlength="20">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-5 col-xs-12">
                                    <div class="form-group">
                                      <label>Direcci&oacute;n</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.direccion" maxlength="200">
                                    </div>
                                  </div>
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>C&oacute;digo postal</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.codpostal" maxlength="10"
                                        (change)="DatosLocalidad()">
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-xs-12">
                                    <div class="form-group">
                                      <label>Localidad</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.localidad" maxlength="80">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>Provincia</label>
                                      <input type="text" class="form-control" [(ngModel)]="data.provincia" maxlength="80">
                                    </div>
                                  </div>
                                  <div class="col-md-3 col-xs-12">
                                    <div class="form-group">
                                      <label>Tel&eacute;fono</label>
                                      <input type="tel" class="form-control" [(ngModel)]="data.telefono" maxlength="20">
                                    </div>
                                  </div>
                                </div>
                                
                                <div class="block">
                                  <div class="block-title"><h2><i class="fa fa-sign-in"></i> Acceso al sistema de reservas</h2></div>
                                  <div class="block-content">
                                    <div class="row">
                                      <div class="col-md-5 col-xs-12">
                                        <div class="form-group">
                                          <label class="text-primary"><i class="fa fa-envelope"></i> Email *</label>
                                          <input type="email" class="form-control"[(ngModel)]="data.email" maxlength="80">
                                        </div>
                                      </div>
                                      <div class="col-md-4 col-xs-12">
                                        <div class="form-group" *ngIf="data.id == ''">
                                          <label class="text-primary"><i class="fa fa-lock"></i> Contraseña *</label>
                                          <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                                          <a class="btn btn-block btn-default m-t-10" (click)="GenerarClave()"><i class="fa fa-lock"></i> Generar</a>
                                        </div>
                                        <div class="form-group" *ngIf="data.id != ''">
                                          <a class="btn btn-sm btn-warning text-white m-t-30" (click)="EditarClave()"><i class="fa fa-lock"></i> Cambiar contraseña</a>
                                        </div>
                                      </div>
                                      <div class="col-md-3 col-xs-12">
                                        <div class="form-group">
                                          <label class="text-primary">Acceso</label>
                                          <select class="form-control" [(ngModel)]="data.acceso">
                                            <option value="1">Sí</option>
                                            <option value="0">No</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                              </div>
                            </div>

                        </div>
                    </div>
                </div>
    
                <div class="col-botones">
                  <a class="btn btn-default" (click)="Cancelar()">Cancelar</a>
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>
<div class="modal inmodal fade" id="modal-editar-clave-usuario" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span class="sr-only">Cerrar</span></button>
            <h4 class="modal-title">{{data.title}}</h4>
        </div>
        <div class="modal-body">
            <p>Nombre: <strong>{{data.nombre}}</strong></p>
            <p>Email: <strong>{{data.email}}</strong></p>
            <div class="form-group row">
                <label class="col-lg-4 col-form-label">Nueva contrase&ntilde;a <span class="text-danger">*</span></label>
                <div class="col-lg-8">
                    <input type="text" class="form-control" [(ngModel)]="data.clave" maxlength="50">
                    <a class="btn btn-block btn-default m-t-10" (click)="GenerarClave()"><i class="fa fa-lock"></i> Generar</a>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
            <button id="btnGuardarClaveUsuarioModal" type="button" class="btn btn-primary" (click)="GuardarClaveUsuario()">Guardar</button>
        </div>
        </div>
    </div>
</div>