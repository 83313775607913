<!-- Full Background -->
<!-- For best results use an image with a resolution of 1280x1280 pixels (prefer a blurred image for smaller file size) -->
<!-- <img src="assets/bg-login-2.jpg" alt="Full Background" class="full-bg animation-pulseSlow" style="object-fit:cover;"> -->
<!-- END Full Background -->

<!-- Login Container -->
<div id="login-container">
    <!-- Login Header -->
    <img src="assets/logo-icono.svg" class="logo logo-cliente logo-login">
    <!-- END Login Header -->

    <!-- Login Block -->
    <div class="block animation-fadeInQuick {{!recuperarclave ? '' : 'hidden'}}">
        <!-- Login Title -->
        <div class="">
            <!-- <img src="assets/logo-empresa.png" class="logo logo-cliente logo-login" style="background-color: white; width: 100%; padding: 25px"> -->
            <h2 class="title-login-form">Acceso al sistema de reservas</h2>
        </div>
        <!-- END Login Title -->

        <!-- Login Form -->
        <div id="form-login" class="form-horizontal">
            <div class="form-group">
                <div class="col-xs-12">
                    <input type="email" id="txtEmail" name="login-email" class="form-control" [(ngModel)]="email" placeholder="Email ..." autocomplete="off">
                </div>
            </div>
            <div class="form-group">
                <div class="col-xs-12">
                    <input type="password" id="txtClave" name="login-password" class="form-control" [(ngModel)]="clave" placeholder="Contrase&ntilde;a ..." autocomplete="off"><i class="fa fa-eye show-hide-pass" style="float: right;"></i>
                </div>
            </div>
            <div class="form-group form-actions">
                <div class="col-xs-12 padding-0">
                    <a id="btnAcceder" class="btn btn-effect-ripple btn-primary text-uppercase" (click)="Login()">Acceder <i class="fa fa-chevron-right m-l-10"></i></a>
                </div>
                <div class="col-xs-12">
                    <label class="csscheckbox csscheckbox-primary">
                        <!-- <input type="checkbox" id="login-remember-me" name="login-remember-me"><span></span> Remember Me? -->
                        <a class="cursor" (click)="recuperarclave = true"><small>¿Has olvidado tu contrase&ntilde;a?</small></a>
                    </label>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <!-- END Login Form -->
    </div>
    <div class="block animation-fadeInQuick {{recuperarclave ? '' : 'hidden'}}">
        <!-- Login Title -->
        <div class="">
            <h2 class="title-login-form">Recuperaci&oacute;n contrase&ntilde;a</h2>
        </div>
        <div id="form-login" class="form-horizontal">
            <p id="text-recuperara-clave">Introduce tu correo y te enviaremos tu nueva contrase&ntilde;a</p>
            <div class="form-group">
                <div class="col-xs-12">
                    <input id="txtEmailRecuperar" type="email" class="form-control" placeholder="Email" [(ngModel)]="email" required>
                </div>
            </div>
            <div class="text-center">
                <a id="btnRecuperarClave" class="btn btn-effect-ripple btn-primary btn-block cursor" (click)="RecuperarClave()">Enviar correo recuperaci&oacute;n</a><br>
                <button id="btnAtras" type="button" class="btn btn-secondary m-t-10 m-b-20" (click)="recuperarclave = false">Atr&aacute;s</button>
            </div>
        </div>
    </div>
    <!-- END Login Block -->

    <!-- Footer -->
    <footer id="footer-copyright" class="text-muted text-center animation-pullUp">
        <small><span id="year-copy"></span><a href="https://pecesgordos.es" target="_blank"><strong>Gestor de reservas y eventos SPG Planner</strong><br/> &copy;Copyright Peces Gordos Estudio</a></small>
    </footer>
    <!-- END Footer -->
</div>
<!-- END Login Container -->