<div id="page-wrapper" class="page-loading">
    <div class="preloader">
        <div class="inner">
            <!-- Animation spinner for all modern browsers -->
            <div class="preloader-spinner themed-background hidden-lt-ie10"></div>

            <!-- Text for IE9 -->
            <h3 class="text-primary visible-lt-ie10"><strong>Cargando ...</strong></h3>
        </div>
    </div>
    <div id="page-container" class="header-fixed-top sidebar-visible-lg-full">
        <!-- Main Sidebar -->
        <app-backend-menu></app-backend-menu>
        <!-- END Main Sidebar -->

        <!-- Main Container -->
        <div id="main-container">
            <!-- Header -->
            <app-backend-header></app-backend-header>
            <!-- END Header -->

            <!-- Page content -->
            <div id="page-content">
                <div class="content-header">
                    <div class="row-equal-cols">
                        <div class="col-lg-8 col-md-6 col-xs-12">
                            <div class="header-section">
                                <h1 class="middle">Configuración App</h1>
                                <!-- <button class="btn btn-xs btn-secondary middle m-l-10" (click)="CargarGrid()"><i class="fa fa-refresh"></i></button> -->
                            </div>
                            <ul class="breadcrumb breadcrumb-top text-left">
                                <li><a [routerLink]="['/dashboard']">Inicio</a></li>
                                <li>Configuración</li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-6 col-xs-12 col-botones text-center">
                          <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                        </div>
                    </div>
                </div>

                <div class="block full break-padding">
                    <div class="block-title">
                        <!-- <div class="block-options pull-right">
                            <a href="javascript:void(0)" class="btn btn-effect-ripple btn-default" data-toggle="tooltip" title=""
                                style="overflow: hidden; position: relative;" data-original-title="Settings"><i
                                    class="fa fa-cog"></i></a>
                        </div> -->
                        <ul class="nav nav-tabs" data-toggle="tabs">
                            <!-- <li class="active"><a href="#tab-general"><i class="icofont-settings-alt"></i> General</a></li> -->
                            <li class="active"><a href="#tab-personalizacion"><i class="icofont-paint"></i> Personalizaci&oacute;n</a></li>
                            <li><a href="#tab-correo"><i class="icofont-envelope"></i> Correo</a></li>
                            <li><a href="#tab-pago"><i class="icofont-credit-card"></i> Pago</a></li>
                            <li><a href="#tab-db"><i class="icofont-database"></i> Base de datos</a></li>
                            <li><a href="#tab-plataformas"><i class="icofont-plugin"></i> Plataformas conectadas</a></li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane active" id="tab-personalizacion">
                            <div class="form-group">
                                <label>Nombre del cliente</label>
                                <input type="text" class="form-control" [(ngModel)]="data.nombre" maxlength="80">
                            </div>
                            <div class="form-group">
                                <label>URL cliente</label>
                                <input type="url" class="form-control" [(ngModel)]="data.url_cliente" maxlength="400">
                            </div>
                            <div class="form-group">
                                <label>URL SPGE HOST (Backend)</label>
                                <input type="url" class="form-control" [(ngModel)]="data.url_spgehost" maxlength="400">
                            </div>
                            <div class="form-group">
                                <label>URL política de cancelación</label>
                                <input type="url" class="form-control" [(ngModel)]="data.url_politica_cancelacion" maxlength="400">
                            </div>
                            <div class="form-group">
                                <label>URL política de privacidad</label>
                                <input type="url" class="form-control" [(ngModel)]="data.url_politica_privacidad" maxlength="400">
                            </div>
                            <label>CSS Personalizado</label>
                            <div id="txtcustomcss" [innerHTML]="data.customcss" style="height:500px;"></div>
                        </div>
                        <div class="tab-pane" id="tab-general">
                        </div>
                        <div class="tab-pane" id="tab-correo">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo salida</h2></div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Host</label>
                                                    <input type="text" class="form-control" [(ngModel)]="data.emailconf_host" maxlength="80">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>Port</label>
                                                    <input type="number" class="form-control" [(ngModel)]="data.emailconf_port" maxlength="12">
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>SSL</label>
                                                    <select class="form-control" [(ngModel)]="data.emailconf_ssl">
                                                    <option value="1">Sí</option>
                                                    <option value="0" selected>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Email para env&iacute;os</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Contraseña de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_pass" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Alias de email para env&iacute;os</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_alias" maxlength="80">
                                        </div>
                                        <hr>
                                        <div class="form-group">
                                            <label>T&iacute;tulo cabecera email</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_titulo_email" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>URL imagen logo</label>
                                            <input type="url" class="form-control" [(ngModel)]="data.emailconf_logo" maxlength="400">
                                        </div>
                                        <div class="form-group">
                                            <label>Color BG Cabecera</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_bgcolor_cabecera" maxlength="20">
                                        </div>
                                        <div class="form-group">
                                            <label>Color texto destacado</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_color_destacado" maxlength="20">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Correo administrador</h2></div>
                                        <div class="form-group">
                                            <label>Alias email administrador</label>
                                            <input type="text" class="form-control" [(ngModel)]="data.emailconf_emailadmin_alias" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 1</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin1" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 2</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin2" maxlength="80">
                                        </div>
                                        <div class="form-group">
                                            <label>Email administrador 3</label>
                                            <input type="email" class="form-control" [(ngModel)]="data.emailconf_emailadmin3" maxlength="80">
                                        </div>
                                    </div>
                                    <div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="EmailTest()"><i class="icofont-envelope"></i> Enviar Test</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-pago">
                            <div class="block">
                                <div class="block-title"><h2>Formas de pago</h2></div>
                                <ng-container *ngFor="let item of data.formas_pago">
                                    <div class="row m-b-10">
                                        <div class="col-md-3 col-xs-12">
                                            <div class="font-bold m-b-10">{{item.forma_pago}}</div>
                                            <div>
                                                <label class="switch switch-success switch-sm m-r-10">
                                                    <div style="font-weight:normal;">Visible web</div>
                                                    <input type="checkbox" [(ngModel)]="item.visible"><span></span>
                                                </label>
                                                <!-- <label class="switch switch-warning switch-sm">
                                                    <div style="font-weight:normal;">Bizum</div>
                                                    <input type="checkbox" [(ngModel)]="item.bizum"><span></span>
                                                </label> -->
                                            </div>
                                        </div>
                                        <div class="col-md-3 col-xs-12">
                                            {{item.slug}}
                                        </div>
                                        <div class="col-md-6 col-xs-12">
                                            <textarea type="text" class="form-control text-editor"
                                            placeholder="Introduce aquí el texto que se incluirá en el email" [(ngModel)]="item.descripcion_email" rows="4"></textarea>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="item.datos">
                                        <div class="m-b-30">
                                            <ng-container *ngIf="item.slug == 'paypal'">
                                                <a class="btn btn-default m-b-10" data-toggle="collapse" href="#config-paypal" role="button" aria-expanded="false">
                                                    Configuración Paypal <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-paypal" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Email</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.email" maxlength="80">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Token</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.token" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="switch switch-warning switch-sm">
                                                                <div style="font-weight:normal;">Entorno pruebas</div>
                                                                <input type="checkbox" [(ngModel)]="item.datos.entorno_pruebas"><span></span>
                                                            </label>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Moneda</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.moneda" maxlength="10">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Url Retorno</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_retorno" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Cancelación</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_cancelacion" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Notificación IPN</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_notificacion_ipn" maxlength="400">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="item.slug == 'tarjeta'">
                                                <a class="btn btn-default m-b-10" data-toggle="collapse" href="#config-tarjeta" role="button" aria-expanded="false">
                                                    Configuración Tarjeta (RedSys) <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-tarjeta" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Sistema</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.sistema" maxlength="10">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Nombre comercio</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.nombre_comercio" maxlength="80">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>FUC comercio</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.fuc_comercio" maxlength="50">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Clave SHA256</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.clave_sha256" maxlength="50">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>Terminal</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.terminal" maxlength="4">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Moneda</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.moneda" maxlength="10">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Retorno</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_retorno" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Error</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_error" maxlength="400">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Url Notificación IPN</label>
                                                            <input type="url" class="form-control" [(ngModel)]="item.datos.url_notificacion_ipn" maxlength="400">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="item.slug == 'stripe'">
                                                <a class="btn btn-default m-b-10" data-toggle="collapse" href="#config-stripe" role="button" aria-expanded="false">
                                                    Configuración Stripe <i class="fa fa-chevron-down"></i>
                                                </a>
                                                <div id="config-stripe" class="row collapse">
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>PK Test</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.pk_test" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>SK Test</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.sk_test" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label class="switch switch-warning switch-sm">
                                                                <div style="font-weight:normal;">Entorno pruebas</div>
                                                                <input type="checkbox" [(ngModel)]="item.datos.entorno_pruebas"><span></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <label>PK Live</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.pk_live" maxlength="200">
                                                        </div>
                                                        <div class="form-group">
                                                            <label>SK Live</label>
                                                            <input type="text" class="form-control" [(ngModel)]="item.datos.sk_live" maxlength="200">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-db">
                            <div class="row">
                                <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                    <div class="block">
                                        <div class="block-title"><h2>Configuración general</h2></div>
                                        <a class="btn btn-default btn-block m-b-20"><input type="checkbox" checked> Comprobar actualizaciones disponibles</a>
                                        <a class="btn btn-default btn-block m-b-20"><input type="checkbox" checked> Descarga de actualizaciones disponibles</a>
                                        <a class="btn btn-default btn-block m-b-20"><input type="checkbox" checked> Instalación de actualizaciones</a>
                                    </div>
                                    <div class="block">
                                        <div class="block-title"><h2>Exportar Base Datos</h2></div>
                                        <a class="btn btn-default btn-block m-b-20" (click)="ExportarDB()"><i class="icofont-database"></i> Exportar</a>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <a [routerLink]="['/reservas']" class="widget custom-widget">
                                        <span class="title-counter sen-extrabold ">Control de versión</span>
                                        <hr class="separador-general" />
                                        <div>
                                            <img src="assets/ce-logo.svg" width="50px" style="margin-top: 25px"/>
                                            <ul style="margin-top: 15px; list-style: none; padding-left: 0px;">
                                                <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;">Verión: 12.5</li>
                                                <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><i class="fa fa-check"></i> Cantidad de archivos: ilimitado</li>
                                                <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><i class="fa fa-check"></i> Usuarios: +10</li>
                                                <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Contrato de usuario</a></li>
                                                <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Aviso de privacidad</a></li>
                                                <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Términos y condiciones</a></li>
                                                <li style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid gray;"><a href="#">Ayuda y soporte</a></li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-lg-6">
                                    <label>Logs DB <a class="btn btn-default" (click)="BorrarLogs()"><i class="fa fa-trash"></i></a></label>
                                    <div id="txtlogsdb" style="height:300px;"></div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="card card-border mb-2">
                                        <div class="card-header card-header-action">
                                            <label>Logs de acceso <button type="button" class="btn btn-sm btn-outline-light" data-bs-toggle="tooltip" data-placement="top"
                                                title="" data-bs-original-title="Refrescar Logs" (click)="CargarLogsAcceso()">
                                                <span>
                                                    <span class="icon"><span class="feather-icon"><i class="fa fa-refresh"></i></span></span>
                                                </span>
                                            </button>
                                            <button type="button" class="btn btn-sm btn-outline-light" data-bs-toggle="tooltip" data-placement="top"
                                                    title="" data-bs-original-title="Exportar Logs Acceso" (click)="ExportarLogsAcceso()" style="position: absolute; right: 5px;">
                                                    <span>
                                                        <span class="icon"><span class="feather-icon"><i class="fa fa-download"></i></span></span>
                                                        <span class="btn-text"> Exportar</span>
                                                    </span>
                                                </button>
                                            </label>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="logs" [innerHTML]="logsacceso" style="max-height:33vh;"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane" id="tab-plataformas">
                            <div class="row integrationsapp-wrap modulos">
                                <div class="col-xxl-3 col-xl-4 col-md-6">
                                    <div class="block m-b-20">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between align-items-start mb-3">
                                                <div class="avatar avatar-logo">
                                                    <span class="initial-wrap">
                                                        <img src="assets/logo-drive.png" alt="Google Drive">
                                                    </span>
                                                </div>
                                                <a class="btn btn-secondary flex-grow-0 flex-shrink-0 flex-basis-0">Conectar</a>
                                            </div>
                                            <div class="app-name">Google Drive</div>
                                            <p class="p-sm multine-ellipsis">Integra tu app con el sistema de archivos.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-4 col-md-6">
                                    <div class="block m-b-20">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between align-items-start mb-3">
                                                <div class="avatar avatar-logo">
                                                    <span class="initial-wrap">
                                                        <img src="assets/logo-calendar.png" alt="Google Calendar">
                                                    </span>
                                                </div>
                                                <a class="btn btn-secondary flex-grow-0 flex-shrink-0 flex-basis-0">Conectar</a>
                                            </div>
                                            <div class="app-name">Google Calendar</div>
                                            <p class="p-sm multine-ellipsis">Integra tu app con calendarios.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-4 col-md-6">
                                    <div class="block m-b-20">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between align-items-start mb-3">
                                                <div class="avatar avatar-logo">
                                                    <span class="initial-wrap">
                                                        <img src="assets/logo-whatsapp.png" alt="WhatsApp">
                                                    </span>
                                                </div>
                                                <a class="btn btn-secondary flex-grow-0 flex-shrink-0 flex-basis-0">Conectar</a>
                                            </div>
                                            <div class="app-name">WhatsApp</div>
                                            <p class="p-sm multine-ellipsis">Integra tu app con WhatsApp.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-4 col-md-6">
                                    <div class="block m-b-20">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between align-items-start mb-3">
                                                <div class="avatar avatar-logo">
                                                    <span class="initial-wrap">
                                                        <img src="assets/logo-keep.png" alt="Google Keep">
                                                    </span>
                                                </div>
                                                <a class="btn btn-secondary flex-grow-0 flex-shrink-0 flex-basis-0">Conectar</a>
                                            </div>
                                            <div class="app-name">Google Keep</div>
                                            <p class="p-sm multine-ellipsis">Integra tu app con notas.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xxl-3 col-xl-4 col-md-6">
                                    <div class="block m-b-20">
                                        <div class="card-body">
                                            <div class="d-flex justify-content-between align-items-start mb-3">
                                                <div class="avatar avatar-logo">
                                                    <span class="initial-wrap">
                                                        <img src="assets/logo-sheets.png" alt="Google Sheets">
                                                    </span>
                                                </div>
                                                <a class="btn btn-secondary flex-grow-0 flex-shrink-0 flex-basis-0">Conectar</a>
                                            </div>
                                            <div class="app-name">Google Sheets</div>
                                            <p class="p-sm multine-ellipsis">Integra tu app con hojas de cálculo.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-botones">
                  <a class="btn btn-primary btn-guardar m-l-20" (click)="Guardar()">Guardar</a>
                </div>

            </div>
            <!-- END Page Content -->
        </div>
        <!-- END Main Container -->
    </div>
    <!-- END Page Container -->
</div>