<div class="response-reserva" *ngIf="!CompraBono">
    <h1>{{LG('¡Gracias por tu reserva!')}}</h1>
    <p>{{LG('A continuación te mostramos los detalles de tu reserva')}}:</p>
    <div class="row row-small datos-reserva" *ngIf="data && data.id">
      <div class="col-xs-6">
        <ul class="list-group">
            <li class="list-group-item active">{{LG('Tus datos')}}</li>
            <li class="list-group-item datos">
              <div>{{data.cli_nombre}} {{data.cli_apellidos}}</div>
              <div *ngIf="data.cli_empresa">{{data.cli_empresa}}<span *ngIf="data.cli_nifcif">, {{data.cli_nifcif}}</span></div>
              <div>{{data.cli_direccion}}</div>
              <div>
                <span *ngIf="data.codigo_postal">{{data.codigo_postal}}, </span>
                <span *ngIf="data.localidad">{{data.localidad}}</span>
              </div>
              <div><span *ngIf="data.cli_pais">, {{data.cli_pais}}</span></div>
              <div *ngIf="data.cli_email"><i class="fa fa-envelope-o"></i> {{data.cli_email}}</div>
              <div *ngIf="data.cli_telefono"><i class="fa fa-phone"></i> {{data.cli_telefono}}</div>
              <div *ngIf="data.cli_notas"><br>{{data.cli_notas}}</div>
            </li>
        </ul>
        <ul class="list-group">
            <li class="list-group-item active">{{LG('Modalidad de pago')}}</li>
            <li class="list-group-item datos">
              <div class="dato-destacado">{{data.cli_tipo_pago}}</div>
            </li>
        </ul>
      </div>
      <div class="col-xs-6">
        <ul class="list-group">
            <li class="list-group-item active">{{LG('Datos de la reserva')}}</li>
            <li class="list-group-item datos">
              <div class="dato-destacado">#{{data.referencia}}</div>
              <div class="dato-destacado">{{data.evento}}</div>
              <div>Fecha del evento: {{data.fecha_format}}</div>
              <div>Sesión del evento: {{data.sesion}}</div>
              <div>{{LG('Adultos')}}: {{data.adultos}}</div>
              <div>{{LG('Niños')}}: {{data.ninos}}</div>
              <div class="hide">{{LG('Niños gratis')}}: {{data.ninos_gratis}}</div>
            </li>
            <ng-container *ngIf="data.descuento">
              <li class="list-group-item">{{LG('Total sin desc.')}}:
                <div class="pull-right">{{data.total_sin_descuento_format}}€</div>
              </li>
              <li class="list-group-item">{{LG('Descuento')}}:
                <div class="pull-right">{{data.descuento}}</div>
              </li>
            </ng-container>
            <li class="list-group-item active font-bold font-20">
              {{LG('Total')}}
              <div class="pull-right">{{data.total_format}} €</div>
            </li>
        </ul>
        <div class="clearfix"></div>
      </div>
    </div>
</div>
<div class="response-reserva" *ngIf="CompraBono">
  <h1>{{LG('¡Gracias por tu reserva!')}}</h1>
  <p>{{LG('A continuación te mostramos los detalles de tu reserva')}}:</p>
  <div class="row row-small datos-reserva" *ngIf="data && data.id">
    <div class="col-xs-6">
      <ul class="list-group">
          <li class="list-group-item active">{{LG('Tus datos')}}</li>
          <li class="list-group-item datos">
            <div>{{data.nombre_regalo}} {{data.apellidos_regalo}}</div>
            <div>
              <span *ngIf="data.codigo_postal_regalo">{{data.codigo_postal_regalo}}, </span>
              <span *ngIf="data.localidad_regalo">{{data.localidad_regalo}}</span>
            </div>
            <div><span *ngIf="data.provincia_regalo">{{data.provincia_regalo}}</span></div>
            <div *ngIf="data.email_regalo"><i class="fa fa-envelope-o"></i> {{data.email_regalo}}</div>
            <div *ngIf="data.telefono_regalo"><i class="fa fa-phone"></i> {{data.telefono_regalo}}</div>
          </li>
      </ul>
      <ul class="list-group" *ngIf="data.dedicatoria">
          <li class="list-group-item active">{{LG('Dedicatoria')}}</li>
          <li class="list-group-item datos">
            <div class="dato-destacado">{{data.dedicatoria}}</div>
          </li>
      </ul>
      <ul class="list-group">
          <li class="list-group-item active">{{LG('Modalidad de pago')}}</li>
          <li class="list-group-item datos">
            <div class="dato-destacado">{{data.cli_tipo_pago}}</div>
          </li>
      </ul>
    </div>
    <div class="col-xs-6">
      <ul class="list-group">
          <li class="list-group-item active">{{LG('Datos del bono regalo')}}</li>
          <li class="list-group-item datos">
            <div class="dato-destacado">Código: {{data.codigo}}</div>
            <div class="dato-destacado">{{data.evento}}</div>
            <hr style="margin: 4px 0;">
            <div>{{data.nombre}} {{data.apellidos}}</div>
            <div>
              <span *ngIf="data.codigo_postal">{{data.codigo_postal}}, </span>
              <span *ngIf="data.localidad">{{data.localidad}}</span>
            </div>
            <div><span *ngIf="data.provincia">{{data.provincia}}</span></div>
            <div *ngIf="data.email"><i class="fa fa-envelope-o"></i> {{data.email}}</div>
            <div *ngIf="data.telefono"><i class="fa fa-phone"></i> {{data.telefono}}</div>
            <hr style="margin: 4px 0;">
            <div>Adultos: {{data.adultos}}</div>
            <div>Niños: {{data.ninos}}</div>
            <div>Niños gratis: {{data.ninos_gratis}}</div>
          </li>
          <li class="list-group-item active font-bold font-20">
            {{LG('Total')}}
            <div class="pull-right">{{data.total_format}} €</div>
          </li>
      </ul>
      <div class="clearfix"></div>
    </div>
  </div>
</div>