import { Component, OnInit, NgZone, ApplicationRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/classes/globals.class';

declare let App: any;
declare let $: any;
declare let swal: any;
declare let moment: any;
declare let toastr: any;

@Component({
  selector: 'app-actividades',
  templateUrl: './actividades.component.html',
  styleUrls: ['./actividades.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActividadesComponent implements OnInit {
  public actividades:any = [];
  public params:any = {
    page_index: 1,
    page_size: 50,
    total: 0,
    pages: 0,
    filtro: null,
    order: null,
    dir: null,
  };
  public aforoV2 = [];
  public diaAforo = {
    id: '',
    id_evento: '',
    aforo_total: '',
    aforo_restante: '',
    fecha: '',
    fecha_format: '',
    timestamp: '',
    ocultar: '',
    aforo_temp: ''
  };
  public reservasDay:any = {};
  public fechas_bloqueadas = [];
  public fechas_reservadas = [];
  public diasCerrados = [];
  public sesiones = [];
  public arrRangosFechasBloqueo:any = {};
  public inputFechaInicioBloqueo = '';
  public inputFechaFinBloqueo = '';
  public data:any = {
    id: '',
    adultos: 2,
    ninos: 0,
    num_minimo_noches: 2,
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false,permanente: true
  };
  public diaSeleccionado:any = '';
  public plazasAdicionales:any = '';
  public actividadDiaSesion:any = {};

  constructor(
    public ngZone: NgZone,
    public router: Router,
    public appref: ApplicationRef,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    App.init();
    $.disableinputnumberwheel();
    $.checkrolredirect(3); //No clientes
    this.CargarGrid();
    this.globals.GridOrderJS((order, dir) => {
      this.params.order = order;
      this.params.dir = dir;
      this.params.page_index = 1;
      this.CargarGrid();
      this.appref.tick();
    });
    $('thead input:checkbox').click(function() {
      var checkedStatus   = $(this).prop('checked');
      var table           = $(this).closest('table');
      $('tbody input:checkbox', table).each(function() {
          $(this).prop('checked', checkedStatus);
      });
    });
    this.CargarCalendario();
  }

  CargarGrid(paginationload:boolean = true) {
    if (this.globals.GetData()) this.actividades = this.globals.GetData();
    this.globals.Api('/eve-eventos', this.params).subscribe(data => {
      if (!data || !data.length) {
        this.actividades = [];
        this.globals.SaveData([]);
        return;
      }
      this.globals.SaveData(data);
      this.actividades = data;
      this.appref.tick();
      $('.content-actividades-gestion-aforo').click(function(){
        $('.content-actividades-gestion-aforo').removeClass('active');
        $(this).addClass('active');
      });
      if (!paginationload) return;
      this.globals.Api('/eve-eventos/total', this.params).subscribe(data => {
        if (!data || !data.length) return;
        this.params.total = data[0].total;
        this.params.pages = Math.ceil(this.params.total / this.params.page_size);
      });
    });
  }
  Paging(dir, table = '') {
    if (dir == 'prev') {
      this.params.page_index -= 1;
    }
    if (dir == 'next') {
      this.params.page_index += 1;
    }
    this.CargarGrid(false);
  }
  Buscar() {
    this.params.page_index = 1;
    this.CargarGrid();
  }
  Nuevo() {
    this.globals.passData = '';
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/add')).then();
  }
  Editar(item:any) {
    this.globals.passData = JSON.parse(JSON.stringify(item));
    this.ngZone.run(() => this.router.navigateByUrl('/actividades/edit')).then();
  }
  Borrar(item:any) {
    swal({
      title: "¿Borrar actividad?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-eventos-del', {id: item.id}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.actividades.splice(this.actividades.findIndex(el => el.id == item.id), 1);
        this.appref.tick();
      });
    });
  }
  Portapapeles(event:any, item:any) {
    event.stopPropagation();
    this.globals.CopyClipboard(item.id);
    toastr.info(`ID Alojamiento: ${item.id}`,'Copiado al portapapeles');
  }
  Duplicar(id_evento:any){
    swal({
      title: "¿Duplicar actividad?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/eve-eventos-duplicate', {id_evento: id_evento}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Se ha producido un error', 'error');return;
        }
        this.appref.tick();
        this.CargarGrid();
      });
    });
  }
  
  CargarCalendario() {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    let that = this;
    var heightCalendar = 425;
    if($(window).width() <= 1366 && $(window).width() > 1025){
      heightCalendar = 380;
    }
    if($(window).width() <= 500){
      heightCalendar = 340;
    }
    $('#calendar').fullCalendar({
        header: {
            left: 'title',
            center: '',
            right: 'today month prev,next'
        },
        defaultView: 'month',
        defaultDate: new Date(),
        locale: 'es',
        allDaySlot: false,
        editable: false,
        droppable: false,
        selectable: false,
        duration: false,
        contentHeight: heightCalendar,
        // //timeFormat: 'H:mm',
        // minTime: '08:00:00',
        // maxTime: '22:00:00',
        viewRender: (view, element) => {
          this.params.fecha_inicio = view.start.format('YYYY-MM-DD');
          this.params.fecha_fin = view.end.format('YYYY-MM-DD');
          this.CargarEventosCalendario();
          $('.calendario-listado-reservas .fc-day-top').click(function(){
            $('.calendario-listado-reservas .fc-day-top').css('background-color','unset');
            $('.calendario-listado-reservas .fc-day-top').css('color','unset');
            $(this).css('background-color','#7AAC3F');
            $(this).css('color','white');
          });
        },
        eventRender: function(event, element) {
        },
        eventClick: function(info) {
        },
        dayClick: (date, allDay, jsEvent, view)=>{
          var tempDate = new Date(date).toDateString();
          this.diaSeleccionado = tempDate;
          var dateFormatted = moment(tempDate).format('YYYY-MM-DD');
          var dateFormatted2 = moment(tempDate).format('DD/MM/YYYY');
          this.LoadAforoByDay(dateFormatted,dateFormatted2);
        }
    });
  }
  CargarEventosCalendario(){
    $('#calendar').fullCalendar('removeEvents');
    var calendar = $('#calendar').fullCalendar('getCalendar');
    var view = calendar.view;
    var start = view.start._d;
    var end = view.end._d;
    let params = {
      fecha_inicio: moment(start).format('YYYY-MM-DD'),
      fecha_fin: moment(end).format('YYYY-MM-DD')
    };
    this.globals.Api('/eve-eventos-dias-cerrados-actividad', {id_evento: this.data.id, fecha_inicio: params.fecha_inicio, fecha_fin: params.fecha_fin}).subscribe(data => {
      if (!data || !data.length) return;
      let daysLock = [];
      if (this.globals.GetData('eventos-calendario-reservas')) {
        daysLock = this.globals.GetData('eventos-calendario-reservas');
        $('#calendar').fullCalendar('renderEvents', daysLock, true);
      }
      for (let item of data) {
        let start = new Date(item.fecha);
        let end = moment(start);
        let color = 'black';
        let cursor = 'pointer';
        let textColor = '#FFF';
        let display = 'block';
        // let className = 'day-red';
        let dayLock = {
          id: item.id,
          className: '',
          //title: `${item.aforo_restante}/${item.aforo_total}`,
          title: '',
          duration: false,
          description: `
            <div style="cursor: ${cursor}">
              <div><b><i class="fa fa-lock" style="display: ${display}; float: left; margin-right: 5px;"> <span style="color: white;"><i class="icofont-check-circled"></i></span></div>
            </div>
          `,
          start: start,
          end: end,
          data: item,
          color: color,
          //color: 'transparent',
          textColor: textColor,
          //textColor: 'transparent',
          disabled: false,
          tipo: 'documento'
        };
        daysLock.push(dayLock);
        // $('#calendar').fullCalendar('renderEvent', event, true);
      }
      $('#calendar').fullCalendar('renderEvents', daysLock, true);
      // $('#calendar').fullCalendar('refetchEvents');
    });
    
    // this.appref.tick();
  }
  
  LoadAforoByDay(fecha:any, fecha2:any){
    this.globals.Api('/eve-eventos-aforo-v2-ficha', {id_evento: this.data.id, fecha: fecha}).subscribe(data => {
      // if (!data || !data.length) return;
      this.aforoV2 = data[0];
      this.reservasDay = data[1];
      var diasCerrados = data[2];
      var diasEspeciales = data[3];
      var plazasAdicionalesDiasEspeciales = data[4];
      if(diasEspeciales.length == 0){
        for(var i = 0; i < this.aforoV2.length; i++){
          this.aforoV2[i].aforo_predeterminado_aux = this.aforoV2[i].aforo_predeterminado;
          this.aforoV2[i].aforo_predeterminado = this.aforoV2[i].aforo_predeterminado + this.aforoV2[i].plazas_adicionales;
          this.aforoV2[i].aforo_disponible = this.aforoV2[i].aforo_predeterminado;
          this.aforoV2[i].sesion = this.aforoV2[i].sesiones;
          this.aforoV2[i].fecha = fecha
          this.aforoV2[i].fecha_format = fecha2
          this.aforoV2[i].personas = 0;
          this.aforoV2[i].porcentage = 100;
          this.aforoV2[i].cerrado = false;
          for(var j = 0; j < this.reservasDay.length; j++){
            if(this.aforoV2[i].sesiones == this.reservasDay[j].sesion){
              var plazasLibres = this.aforoV2[i].aforo_predeterminado - this.reservasDay[j].personas;
              this.aforoV2[i].porcentage = (plazasLibres * 100) / this.aforoV2[i].aforo_predeterminado;
              this.aforoV2[i].aforo_disponible = plazasLibres;
              this.aforoV2[i].personas = this.aforoV2[i].aforo_predeterminado - plazasLibres;
              this.aforoV2[i].sesion = this.reservasDay[j].sesion;
              this.aforoV2[i].fecha_format = this.reservasDay[j].fecha_format;
            }
          }
          if(diasCerrados != null){
            for(var k = 0; k < diasCerrados.length; k++){
              if(this.aforoV2[i].sesion == diasCerrados[k].sesion){
                this.aforoV2[i].cerrado = true;
              }
            }
          }
        }
      }else{
        this.aforoV2 = [];
        var arrSesiones = diasEspeciales[0].sesiones.split(',');
        //console.log(arrSesiones);
        //console.log(arrSesiones.lenght);
        arrSesiones.forEach(sesion => {
          let aforo:any = {};
          aforo.aforo_predeterminado = diasEspeciales[0].aforo;
          aforo.aforo_disponible = diasEspeciales[0].aforo;
          aforo.aforo_predeterminado_aux = aforo.aforo_predeterminado;
          aforo.sesion = sesion;
          aforo.fecha = fecha
          aforo.fecha_format = fecha2
          aforo.personas = 0;
          aforo.porcentage = 100;
          aforo.cerrado = false;
          // //console.log(aforo);
          plazasAdicionalesDiasEspeciales.forEach(plazasAdicionalesDiaEspecial => {
            if(plazasAdicionalesDiaEspecial.sesion == sesion){
              aforo.aforo_predeterminado =  aforo.aforo_predeterminado + plazasAdicionalesDiaEspecial.plazas_adicionales;
              aforo.aforo_disponible = aforo.aforo_predeterminado;
              aforo.plazas_adicionales = plazasAdicionalesDiaEspecial.plazas_adicionales;
            }
          });

          for(var j = 0; j < this.reservasDay.length; j++){
            if(sesion == this.reservasDay[j].sesion){
              var plazasLibres = aforo.aforo_predeterminado - this.reservasDay[j].personas;
              aforo.porcentage = (plazasLibres * 100) / aforo.aforo_predeterminado;
              aforo.aforo_disponible = plazasLibres;
              aforo.personas = aforo.aforo_predeterminado - plazasLibres;
              aforo.sesion = this.reservasDay[j].sesion;
              aforo.fecha_format = this.reservasDay[j].fecha_format;
              
            }
          }
          if(diasCerrados != null){
            for(var k = 0; k < diasCerrados.length; k++){
              if(aforo.sesion == diasCerrados[k].sesion){
                aforo.cerrado = true;
              }
            }
          }
          this.aforoV2.push(aforo);
        });
        //console.table(this.aforoV2);
      }
      //console.table(data);
    }, error => {
      toastr.error('Error al cargar el aforo calendario','Error');
    });
  }
   CargarRangosBloqueos(){
    this.globals.Api('/bloquear-rango-fechas-cargar', {id_evento: this.data.id}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al guardar bloqueo de fechas', 'error');return;
      }
      this.arrRangosFechasBloqueo = data;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  GuardarRangosFecha(){
    if(!this.inputFechaInicioBloqueo || !this.inputFechaFinBloqueo) return;
    this.globals.Api('/bloquear-rango-fechas', {id_evento: this.data.id, fecha_inicio: this.inputFechaInicioBloqueo, fecha_fin: this.inputFechaFinBloqueo, sesiones: this.sesiones}).subscribe(data => {
      if (!data || data.error) {
        swal('Error', 'Error al guardar bloqueo de fechas', 'error');return;
      }
      this.CargarEventosCalendario();
      this.CargarRangosBloqueos();
      this.inputFechaInicioBloqueo = null;
      this.inputFechaFinBloqueo = null;
    }, error => {
      // toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  EliminarRangosFecha(id_rango_fecha:any){
    swal({
      title: "¿Borrar rango?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-primary",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/bloquear-rango-fechas-del', {id_rango_fecha: id_rango_fecha}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Error al borrar bloqueo de fechas', 'error');return;
        }
        this.CargarEventosCalendario();
        this.CargarRangosBloqueos();
      }, error => {
        // toastr.error(`Al bloquear sesión`, 'Error');
      });
    });
  }
  SeleccionarActividad(id_actividad:any){
    this.data.id = id_actividad;
    this.aforoV2 = [];
    if(this.diaSeleccionado == ''){
      this.CargarEventosCalendario();
      this.CargarRangosBloqueos();
      this.LoadSesiones();
      var dateFormatted = moment(new Date()).format('YYYY-MM-DD');
      var dateFormatted2 = moment(new Date()).format('DD/MM/YYYY');
      this.LoadAforoByDay(dateFormatted,dateFormatted2);
      $('.fc-content-skeleton .fc-today').click();
    }else{
      this.CargarEventosCalendario();
      this.CargarRangosBloqueos();
      this.LoadSesiones();
      var dateFormatted = moment(this.diaSeleccionado).format('YYYY-MM-DD');
      var dateFormatted2 = moment(this.diaSeleccionado).format('DD/MM/YYYY');
      this.LoadAforoByDay(dateFormatted,dateFormatted2);
    }
  }
  BloquearDiaSesion(aforo:any){
    this.globals.Api('/eve-eventos-aforo-lock-v2', {id_evento: this.data.id, fecha: aforo.fecha, sesion: aforo.sesion}).subscribe(data => {
      this.LoadAforoByDay(aforo.fecha,aforo.fecha_format);
      this.CargarEventosCalendario();
    }, error => {
      toastr.error(`Al bloquear sesión`, 'Error');
    });
  }
  DesbloquearDiaSesion(aforo:any){
    this.globals.Api('/eve-eventos-aforo-unlock-v2', {id_evento: this.data.id, fecha: aforo.fecha, sesion: aforo.sesion}).subscribe(data => {
      this.LoadAforoByDay(aforo.fecha,aforo.fecha_format);
      this.CargarEventosCalendario();
    }, error => {
      toastr.error(`Al desbloquear día especial`, 'Error');
    });
  }
  LoadSesiones(){
    this.globals.Api('/eve-eventos-sesiones-actividad', {id: this.data.id}).subscribe(data => {
      if (!data || !data.length) return;
      this.sesiones = data;
    });
  }
  EditarPlazasAdicionales(ActividadDiaSesion:any){
    this.actividadDiaSesion = ActividadDiaSesion;
    $('#modal-editar-plazas').modal('show');
  }
  GuardarPlazasAdicionales(){
    // console.log(this.actividadDiaSesion);
    // console.log(this.data.id);
    // console.log(this.plazasAdicionales);
    let plazasAdicionalesCalculadas = 0;
    plazasAdicionalesCalculadas = this.plazasAdicionales - this.actividadDiaSesion.aforo_predeterminado_aux;
    // console.log(plazasAdicionalesCalculadas);
    if(this.plazasAdicionales == 0 || this.plazasAdicionales == '' || this.plazasAdicionales == null){
      plazasAdicionalesCalculadas = 0;
    }
    this.globals.Api('/eve-plazas-adicionales-add', {id_evento: this.data.id, plazas: plazasAdicionalesCalculadas, fecha: this.actividadDiaSesion.fecha, sesion: this.actividadDiaSesion.sesion}).subscribe(data => {
      if (!data || data.error){
        swal('Error','Error al establecer las plazas','error')
        return;
      }else{
        this.plazasAdicionales = '';
        $('#modal-editar-plazas').modal('hide');
        var dateFormatted = moment(this.diaSeleccionado).format('YYYY-MM-DD');
        var dateFormatted2 = moment(this.diaSeleccionado).format('DD/MM/YYYY');
        this.LoadAforoByDay(dateFormatted,dateFormatted2);
    }
    });
  }
  CerrarModalPlazasAdicionales(){
    this.plazasAdicionales = '';
    $('#modal-editar-plazas').modal('hide');
  }
  cerrarActividadesFecha(){
    if(!this.diaSeleccionado){
      swal('Error','Selecciona un día primero','error');
      return;
    } 
    var dateFormatted = moment(this.diaSeleccionado).format('YYYY-MM-DD');
    var dateFormatted2 = moment(this.diaSeleccionado).format('DD/MM/YYYY');
    swal({
      title: "¿Estás seguro de cerrar todas las actividades para la fecha "+dateFormatted2+"?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-default",
      confirmButtonText: "Sí",
      cancelButtonClass: "btn-primary",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/bloquear-todas-actividades-fecha', {fecha: dateFormatted}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Error al cerrar actividades', 'error');return;
        }
        this.CargarEventosCalendario();
        this.CargarRangosBloqueos();
        this.SeleccionarActividad(this.data.id);
      }, error => {
        // toastr.error(`Al bloquear sesión`, 'Error');
      });
    });
  }
  abrirActividadesFecha(){
    if(!this.diaSeleccionado){
      swal('Error','Selecciona un día primero','error');
      return;
    } 
    var dateFormatted = moment(this.diaSeleccionado).format('YYYY-MM-DD');
    var dateFormatted2 = moment(this.diaSeleccionado).format('DD/MM/YYYY');
    swal({
      title: "¿Estás seguro de abrir todas las actividades para la fecha "+dateFormatted2+"?",
      type: "warning",
      showCancelButton: true,
      confirmButtonClass: "btn-default",
      confirmButtonText: "Sí",
      cancelButtonClass: "btn-primary",
      cancelButtonText: "No",
      closeOnConfirm: true
    },() => {
      this.globals.Api('/desbloquear-todas-actividades-fecha', {fecha: dateFormatted}).subscribe(data => {
        if (!data || data.error) {
          swal('Error', 'Error al abrir actividades', 'error');return;
        }
        this.CargarEventosCalendario();
        this.CargarRangosBloqueos();
        this.SeleccionarActividad(this.data.id);
      }, error => {
        // toastr.error(`Al bloquear sesión`, 'Error');
      });
    });
  }
}
